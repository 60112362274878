export const PREPAID_SERVICES =[
    'c1f9cd04-d592-4667-a606-ac2a05b74923',
    '11ba7b04-1f57-42ff-9ccc-9355f319a981',
    '4806296d-8631-438c-bd2a-719491e90e90',
    '5d14e80e-5a64-4248-ac69-ed6bf94fb544',
    'c7e9fb26-14cb-412b-b936-98c41e629c78',
    '6cb88808-9b2c-4924-b94a-ce1972d9af2f',
    'b05b7a1d-c6b1-427b-9c0b-61281cc58514',
    'ba41623e-c7e1-4186-9e4b-685de888685f',
    '4eb3ec7f-1a51-44cb-b2a9-f55ddd560b56',
    'e57e06e6-60d8-4e31-9cb5-1347426fa2e0'
];
export const FOUR_G_PHONE = [
    '73315190-9121-466e-a98e-2a0e6a8c6f4f'
]
export const PREPAID_UNLIMITED_4G = [
    'a911af35-30d0-4e0d-a407-633d27ad8f3a'
]
export const PREPAID_AND_LEGACY = [
    ...FOUR_G_PHONE,
    ...PREPAID_SERVICES,
    ...PREPAID_UNLIMITED_4G
]

export const ROUTES = {
    accountDetails: '/manage/account-details',
    fourGMobile: '/4Gmobile',
    editAccountDetails: '/manage/editPayment',
    arrearsPage:'/manage/account-arrears',
    partialPayment: '/manage/partial-payment',
    promiseToPay: '/manage/promise-to-pay',
  };
export const PREPAID_SERVICES_GIG_LIMIT =[
    'c1f9cd04-d592-4667-a606-ac2a05b74923',
    '11ba7b04-1f57-42ff-9ccc-9355f319a981',
    '4806296d-8631-438c-bd2a-719491e90e90',
    '5d14e80e-5a64-4248-ac69-ed6bf94fb544',
    'c7e9fb26-14cb-412b-b936-98c41e629c78',
    '6cb88808-9b2c-4924-b94a-ce1972d9af2f',
    'b05b7a1d-c6b1-427b-9c0b-61281cc58514',
    'ba41623e-c7e1-4186-9e4b-685de888685f',
    'e57e06e6-60d8-4e31-9cb5-1347426fa2e0'
]

export const NVIDIA_IDS = [
    '4e4a8a59-12a1-4f18-bcd5-e32f4b9222d7',
    'ccce762f-f3d5-4cdb-8102-88c2bd70b899',
    '45e497d9-f108-4c52-9b8f-f38345c0ee61',
    '99adb2a0-036c-44f7-99b1-a755d44eee75',
    'a7d390d2-beff-4ba8-9fe7-3061a2074bb3',
    '6d52eed9-1828-47f3-9015-4d23087eb6fd',
    'c1f8a970-68c8-4b59-abd4-544761de257f',
    '2170ea26-d0f3-46a1-a8a6-47d41810b36a',
    '99adb2a0-036c-44f7-99b1-a755d44eee75',
    'a7d390d2-beff-4ba8-9fe7-3061a2074bb3',
    '168d61bc-c992-4485-811b-371a99b086c8',
    'e95f57c2-9878-4206-986d-c54bfddea090'
  ]

  export const UPGRADABLE_IDS = [
    "db3495c4-e9b5-4ea0-9a6a-30cdb3b52d62",
    "0268ed3c-0def-433d-ad37-8ccf629dbd68",
    "7a827301-9dd9-45fa-a1e1-b790fcda0f97",
    "f53c737d-a61a-47af-a486-9b371acb6a96",
    'c1f9cd04-d592-4667-a606-ac2a05b74923',
    '5d14e80e-5a64-4248-ac69-ed6bf94fb544',
    '11ba7b04-1f57-42ff-9ccc-9355f319a981',
    '70e483ec-deee-4727-9499-348ba07b020d',
    '4806296d-8631-438c-bd2a-719491e90e90'
  ]
