import { Component, HostListener, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { ClickToClaimState } from 'src/app/store/state/click-to-claim.state';
import { Select, Store } from '@ngxs/store';
import { trigger, style, animate, transition } from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FetchClickToClaim } from 'src/app/store/actions/click-to-claim.actions';
import { ConfirmationModalComponent } from '@components/confirm-modal/confirm-modal.component';
import { takeUntil } from 'rxjs/operators';
import { SupportService } from '@services/support.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataLayerService, NEW_POP_ACTIONS } from '@services/data-layer.service';
import { RainAnalyticsTrackerClick } from 'src/app/store/interfaces/rain-analytics-tracker';
import { ButtonClickedTracking } from 'src/app/store/actions/rain-analytics-tracker.actions';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';
import { RainFlags } from 'src/app/core/interfaces/rain-flags.interface';

@Component({
  selector: 'rain-exit-pop',
  templateUrl: './exit-pop.component.html',
  styleUrls: ['./exit-pop.component.scss'],
  animations: [
    trigger('modalAnimation', [
      transition('void => enter', [
        style({ opacity: 0, transform: 'scale(0.0)' }),
        animate('800ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition('enter => leave', [animate('500ms ease-in-out', style({ opacity: 0, transform: 'scale(0.5)' }))])
    ])
  ]
})
export class ExitPopComponent implements OnInit {
  @Select(FirebaseConfigsState.getFlags) flags$: Observable<RainFlags>;
  isMobile: boolean = false;
  product:string;
  fromPage:string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateMobileFlag();
  }
  phoneForm: FormGroup;
  @Select(ClickToClaimState.getClickToClaimValues) clickClaim$: Observable<any>;
  public voucherAmount: any = 0;
  showModal = true;
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private store: Store,
    private modalService: NgbModal,
    private supportService: SupportService,
    private activatedRoute: ActivatedRoute,
    private dataLayerService: DataLayerService,
    private router: Router,
  ) {
    this.phoneForm = this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.maxLength(12), Validators.pattern(/^\d+$/)]]
    });
  }

  ngOnInit(): void {
    this.updateMobileFlag();
    this.store.dispatch(new FetchClickToClaim());
    this.clickClaim$.subscribe(clickClaimData => {
      if (clickClaimData) {
        this.voucherAmount = clickClaimData?.exitvoucher;
      }
    });
  }

  allowOnlyNumbers(event: KeyboardEvent): void {
    const charCode = event.key.charCodeAt(0);
    if (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'Tab' ||
      ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)
    ) {
      return;
    }

    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  private updateMobileFlag() {
    this.isMobile = window.innerWidth < 768;
  }

  onSubmit(): void {
    if (this.phoneForm.valid) {
      const gclId = this.activatedRoute.snapshot.queryParams['gclid'] ?? '';
      const body: any = {
        message: `<h2> subject: request a rainone ${this.product} call # ${this.phoneForm.value.phoneNumber}</h2>
        <p style= "font-size: 14px;">(name): </p>
        <p style= "font-size: 14px;">(tel): ${this.phoneForm.value.phoneNumber}</p>
        <p style= "font-size: 14px;">(email): </p>
        <p style= "font-size: 14px;">(product): </p>
        <p style= "font-size: 14px;">(questions): </p>
        <p style= "font-size: 14px;">(platform): WEB</p>
        <p style= "font-size: 14px;">(requestedFrom): ${this.fromPage}</p>
        <p style= "font-size: 14px;">(Exit Pop voucher): R${this.voucherAmount} </p>
        <p style= "font-size: 14px;">(ga_tag): </p>
        <p style= "font-size: 14px;">(gcl_id): ${gclId}</p>
        <p style= "font-size: 14px;">(xtenders): </p>
        <p style= "font-size: 14px;">(selected speed):</p>`
      };
      const ticketRequest = {
        name: null,
        address: null,
        email: null,
        subject: `request a rainone ${this.product} call # ${this.phoneForm.value.phoneNumber}`,
        referenceId: this.phoneForm.value.phoneNumber,
        body,
        captcha: null,
        ga_tag: null,
        gclId,
        clickToClaimAmount: this.voucherAmount,
        selectedSpeed: null,
        xtenderCount: null
      };

      this.supportService
        .addTicket(ticketRequest)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (result: any) => {
            if (result) {
              if (!result?.ticket) {
                return;
              } else {
                this.activeModal.close();
                this.dataLayerService.handleNewPopupTriggers(NEW_POP_ACTIONS.submit);
                this.openThankYou();
                this.dataLayerService.requestSalesCall(result?.ticket?.ticket?.id, 'exit pop');

                const rainTrackingClickOj: RainAnalyticsTrackerClick = {
                  buttonId: `request-a-exit-pop-call-${this.phoneForm.value.phoneNumber}`,
                  buttonLabel: `request-a-exit-pop-call-voucher-${this.voucherAmount}`,
                  event: 'btn-click'
                }
                this.store.dispatch(new ButtonClickedTracking(rainTrackingClickOj))
              }
            }
          },
          error: err => {}
        });
    }
  }

  openThankYou() {
    const data = {
      title: 'thank you',
      description: 'We have received your request. An agent will contact you as soon as they are available.',
      buttonName: 'got it'
    };
    const options = { size: 'md' as any, windowClass: 'slideInUp', centered: true };
    const thankYouModalRef = this.modalService.open(ConfirmationModalComponent, options);
    thankYouModalRef.componentInstance.data = data;
  }

  closeModal() {
    this.showModal = false;
    setTimeout(() => {
      this.activeModal.close();
    }, 300);
  }

  goToPromoTerms() {
    this.closeModal();
    this.router.navigateByUrl('/legal?section=promo');
}
}
