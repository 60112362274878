import { BillCycleOption } from "src/app/core/interfaces/bill-cycle-option.interface";

export const parseBillingCycleDates = (dates: Array<BillCycleOption>): any[] => {
    return dates.map((d: BillCycleOption) => {
      const label = d.start_day === 1 ? `${d.start_day}st` : `${d.start_day}th`;
      return {
        value: String(d.start_day),
        id: d.id,
        label
      };
    });
  }

export function displayCorrectMonth(day: number) {
    let today = new Date();
    let dayWithSuffix = addOrdinalSuffix(day?.toString());
    if (today.getDate() >= day) {
        today.setDate(1); // Set to first of month to prevent end of month wrap around.
        // 30 January then wraps to March because Feb doesn't have 30 days
        const nextMonth = new Date(today.setMonth(today.getMonth() + 1));
        return dayWithSuffix + ' of ' + nextMonth.toLocaleString('default', {month: 'long'});
    } else {
        return dayWithSuffix + ' of ' + today.toLocaleString('default', {month: 'long'});
    }
}

export function addOrdinalSuffix(day: string): string {
    if (day == '1') return '1st';
    if (day == '15') return '15th';
    if (day == '20') return '20th';
    if (day == '25') return '25th';
    return day;
}
