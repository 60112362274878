import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_IDM_TOKEN, CACHE_MML_DATA } from '@models/constants';
import { ModelHelper } from '@models/modelHelper';
import { IRemoteSubscriberDetail } from '@models/remote/remoteSimTowerDetail';
import { Result } from '@models/result';
import { FeatureCollection, Point } from 'geojson';
import { isNil } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout, tap, take } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { ConfigService } from './config.service';
import { ServiceHelper } from './serviceHelper';
import { ISubscriberDetail, SubscriberDetail } from './simTowerDetail';
import { TokenService } from './token.service';
import { ITowerDetail, TowerDetail } from './towerDetail';

const API_TIMEOUT = 2000;

export enum RainNetworkDeviceTypes {
  None = 0,
  ConnectedComputer,
  Dongle,
  eBook,
  Handheld,
  IOTDevice,
  MobilePhoneFeaturePhone,
  MobileTestPlatform,
  Modem,
  Module,
  Portable,
  Smartphone,
  Tablet,
  Vehicle,
  Wearable,
  WLANRouter
}

export interface IRemoteRainNetwordDetail {
  imei: string;
  timestamp: number;
  service_name: string;
  device_name: string;
  device_type: string;
}

export interface IRainNetwordDetail {
  imei: string;
  timestamp: Date;
  serviceName: string;
  deviceName: string;
  deviceType: RainNetworkDeviceTypes;
}

class RainNetwordDetail {
  static adapt(remote: IRemoteRainNetwordDetail): IRainNetwordDetail {
    return {
      imei: remote.imei,
      deviceName: remote.device_name,
      serviceName: remote.service_name,
      timestamp: ModelHelper.fromTimestamp(remote.timestamp),
      deviceType: RainNetwordDetail.mapDeviceType(remote.device_type)
    } as IRainNetwordDetail;
  }

  static mapDeviceType(remoteDeviceType: string): RainNetworkDeviceTypes {
    switch (remoteDeviceType) {
      case 'Connected Computer':
        return RainNetworkDeviceTypes.ConnectedComputer;
      case 'Dongle':
        return RainNetworkDeviceTypes.Dongle;
      case 'e-Book':
        return RainNetworkDeviceTypes.eBook;
      case 'Handheld':
        return RainNetworkDeviceTypes.Handheld;
      case 'IOT Device':
        return RainNetworkDeviceTypes.IOTDevice;
      case 'Mobile Phone/Feature phone':
        return RainNetworkDeviceTypes.MobilePhoneFeaturePhone;
      case 'Mobile Test Platform':
        return RainNetworkDeviceTypes.MobileTestPlatform;
      case 'Modem':
        return RainNetworkDeviceTypes.Modem;
      case 'Module':
        return RainNetworkDeviceTypes.Module;
      case 'Portable(include PDA)':
        return RainNetworkDeviceTypes.Portable;
      case 'Smartphone':
        return RainNetworkDeviceTypes.Smartphone;
      case 'Tablet':
        return RainNetworkDeviceTypes.Tablet;
      case 'Vehicle':
        return RainNetworkDeviceTypes.Vehicle;
      case 'Wearable':
        return RainNetworkDeviceTypes.Wearable;
      case 'WLAN Router':
        return RainNetworkDeviceTypes.WLANRouter;
      default:
        return RainNetworkDeviceTypes.None;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class TowerService {
  constructor(
    private cacheService: CacheService,
    private configService: ConfigService,
    private http: HttpClient,
    private tokenService: TokenService
  ) { }

  getBounds(viewport: google.maps.LatLngBounds): Observable<Result<ITowerDetail[]>> {
    if (isNil(viewport)) {
      return of(Result.success([]));
    }

    const requestPath = `${this.configService.AXIOM_IDM_URL}/state/findnearbysquare`;
    const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + idmToken
      })
    };

    //TODO swapped around from api
    const body = {
      topLat: viewport.getSouthWest().lat(),
      topLon: viewport.getSouthWest().lng(),
      bottomLat: viewport.getNorthEast().lat(),
      bottomLon: viewport.getNorthEast().lng()
    };

    return this.http.post(requestPath, body, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((remote: FeatureCollection<Point>) => {
        const towerDetails = remote.features.map(x => TowerDetail.fromFeature(x));

        return Result.success(towerDetails);
      }),
      catchError(result => ServiceHelper.handleError<ITowerDetail[]>(result))
    );
  }

  getRainNetwordInfo(): Observable<Result<IRainNetwordDetail>> {
    const requestPath = `${this.configService.RAIN_NETWORK_CHECK_API}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(API_TIMEOUT),
      map((remote: any) => {
        const networkInfo = RainNetwordDetail.adapt(remote);

        return Result.success(networkInfo);
      }),
      catchError(result => ServiceHelper.handleError<IRainNetwordDetail>(result))
    );
  }

  public getSubscriberDetail(msisdn: string): Observable<Result<ISubscriberDetail>> {
    const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);
    const requestPath = `${this.configService.AXIOM_IDM_URL}/state/subscriber/${msisdn}`;
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + idmToken
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      // tap(r => console.log(r)),
      take(1),
      timeout(this.configService.API_TIMEOUT),
      map((remote: IRemoteSubscriberDetail) => {
        const subscriberDetail = SubscriberDetail.adapt(remote);

        return Result.success(subscriberDetail);
      }),
      catchError(result => ServiceHelper.handleError<ISubscriberDetail>(result))
    );
  }

  public fetchImsi(msisdn: string) {
    const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey':'pQk3kfcvUBK6nnlKprMHtLAgYMrWbOLK'
      })
    };
    // https://prod-bss-cf.rain.co.za/v1/bss-idm/state/subscriber/27816693325
    // https://prod-bss-cf.rain.co.za/v1/bss-idm/state/subscriber/
    // 'https://prod-bss-api.rain.co.za/v1/subscriberstate/279609802205190',
    const url = ` https://prod-bss-api.rain.co.za/v1/bss-idm/state/subscriberstate/${msisdn}`;
    return this.http.get(url, httpOptions);
  }

  public getMMLSubscriberDetail(msisdn: string): Observable<Result<ISubscriberDetail>> {
    if (this.isCached(msisdn)) {
      const cached = this.getCache(msisdn);
      return of(Result.success(cached));
    }

    const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);

    // const requestPath = `${this.configService.AXIOM_IDM_URL}/state/subscriber/${msisdn}`;
    const requestPath = `${this.configService.AXIOM_IDM_URL}/state/subscriber/refresh/${msisdn}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + idmToken
      })
    };

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((remote: IRemoteSubscriberDetail) => {
        const subscriberDetail = SubscriberDetail.adapt(remote);
        this.setCache(msisdn, subscriberDetail);
        return Result.success(subscriberDetail);
      }),
      catchError(result => ServiceHelper.handleError<ISubscriberDetail>(result))
    );
  }


  isCached(msisdn): boolean {
    return this.cacheService.exists(CACHE_MML_DATA + '-' + msisdn);
  }

  getCache(msisdn): ISubscriberDetail {
    return this.cacheService.getObject<ISubscriberDetail>(CACHE_MML_DATA + '-' + msisdn);
  }

  setCache(msisdn: string, details: ISubscriberDetail) {
    this.cacheService.setObject(CACHE_MML_DATA + '-' + msisdn, details, 3600000);
  }
}
