<div [@modalAnimation]="showModal ? 'enter' : 'leave'" class="popup-container">
    <div class="popup">
      <div class="close-btn">
        <img alt="Close" aria-label="Close" class="pointer" (click)="closeModal()"
             data-dismiss="modal" data-target="#thankYouModal" src="../../assets/images/exit-pop/close-it.png"/>
      </div>
     <div class="free-month-header" *ngIf="(flags$ | async)?.first_month_free">
      <h1 style="text-transform: none;"> get your first month FREE*</h1>
     </div>
      <div class="grid-container">
        <div class="form-container">
          <p class="free-copy" *ngIf="(flags$ | async)?.first_month_free; else notFreePromo">
            <span class="free-month-title">pay <strong>R1</strong> to join.<br/></span>
            Available on all rain products. Limited offer.
          </p> 
          <ng-template #notFreePromo>
            <h1>new here?</h1>
            <p>Let us call you and offer you {{ voucherAmount | currency:'R':'symbol':'1.0-0' }} to sign up and save.</p>
            <ng-container >
                <h2>get {{ voucherAmount | currency:'R':'symbol':'1.0-0' }} off</h2>
            </ng-container> 
          </ng-template>
  
        <div>

          <div *ngIf="isMobile" class="image-container image-container-spacer">
            <span class="overlay-span-tile-click" (click)="goToPromoTerms()"></span>
            <img 
              [src]="(flags$ | async)?.first_month_free 
                ? '/assets/images/exit-pop/popup_asset-free.webp' 
                : '/assets/images/exit-pop/popup_asset.webp'" 
              alt="Router"
            >
          </div>

          <form [formGroup]="phoneForm" (ngSubmit)="onSubmit()">
            <div>
              <input
                id="phoneNumber"
                type="text"
                formControlName="phoneNumber"
                maxlength="12"
                minlength="10"
                (input)="phoneForm.get('phoneNumber')?.updateValueAndValidity()"
                (keypress)="allowOnlyNumbers($event)"
                placeholder="enter your phone number"
              />
            </div>
            <button class="call-btn" type="submit" [disabled]="phoneForm.invalid">call me now</button>
          </form>
        </div>
        
        </div>
        <div *ngIf="!isMobile" class="image-container image-container-spacer">
          <span class="overlay-span-tile-click" (click)="goToPromoTerms()"></span>
          <img 
            [src]="(flags$ | async)?.first_month_free 
              ? '/assets/images/exit-pop/popup_asset-free.webp' 
              : '/assets/images/exit-pop/popup_asset.webp'" 
            alt="Router"
          >
        </div>
        
      </div>

    </div>

  </div>
  <p class="terms">
    By requesting a call you are agreeing to our 
    <a href="/legal?section=terms" target="_blank">Terms of Service</a> & 
    <a href="/legal?section=policies" target="_blank">Privacy Policy</a>
  </p>