import { Utils } from "src/app/utils";
import { UserAddressPayload } from "../../interfaces/user-address.interface";
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { UserAddress } from "@services/coverageDetail";

export class SetUserCoverageAddress {
    static readonly type = Utils.Helpers.Type('[Core: Set] Set user coverage check address');
    constructor(public payload: GoogleAddress) {}
}

export class PostUserCoverageAddress {
    static readonly type = Utils.Helpers.Type('[Core: Post] Post user coverage check address');
    constructor(public payload: string) {}

}

export class PostUserCoverageAddressSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Post] Post user coverage check address success');
    constructor(public payload: any) {}
}

export class PostUserCoverageAddressFail {
    static readonly type = Utils.Helpers.Type('[Core: Post] Post user coverage check address fail');
    constructor(public payload: any) {}
}

export class SetUserAddress {
     static readonly type = Utils.Helpers.Type('[Core: Set] Set user address');
     constructor( public address : UserAddress){}
}

export class ClearCoverageState {
    static readonly type = Utils.Helpers.Type('[CoverageState: Clear] Clear');
    constructor() {}
}