import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { UIState } from './store/state/ui.state';
import { BarcodeGeneratorComponent } from './components/barcode-generator/barcode-generator.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { SafePipe } from './pipes/safe.pipe';
import { AccordionComponent } from './components/accordion/accordion.component';
import { RainFormsModule } from './components/rain-forms/rain-forms.module';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { FallbackModalComponent } from './components/fallback-modal/fallback-modal.component';
import { ProductCategoryHeaderComponent } from '@components/product/product-list/product-category-header/product-category-header.component';
import { TotalDueNowPipe } from './pipes/total-due-now.pipe';
import { RainOneLevelsComponent } from './components/rain-one-levels/rain-one-levels.component';
import { RainOneLevelsModalComponent } from './components/rain-one-levels-modal/rain-one-levels-modal.component';
import { RainOneAddPhoneLineModalComponent } from './components/rain-one-add-phone-line-modal/rain-one-add-phone-line-modal.component';
import { RainOneAddWifiSpeedModalComponent } from './components/rain-one-add-wifi-speed-modal/rain-one-add-wifi-speed-modal.component';
import { RainOneWifiLevelsComponent } from './components/rain-one-wifi-levels/rain-one-wifi-levels.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BuyMoreComponent } from '@pages/service-page/buy-more/buy-more.component';
import { RainOneBundleComponent } from '@pages/home/rain-one-bundle/rain-one-bundle.component';
import { RainOneProductComponent } from '@pages/home/rain-one-product/rain-one-product.component';
import { RainOneAddOnsComponent } from '@pages/home/rain-one-add-ons/rain-one-add-ons.component';
import { QuickLinksRainOneComponent } from '@pages/support-page/quick-links/quick-links-rain-one.component';
import { QuickLinksGeneralComponent } from '@pages/support-page/quick-links/quick-links-general.component';
import { CartErrorModalComponent } from './components/cart-error-modal/cart-error-modal.component';
import { RainOneOutOfCoverageModalComponent } from './components/rain-one-out-of-coverage-modal/rain-one-out-of-coverage-modal.component';
import { SupportButtonModule } from '@components/support-button/support-button.module';
import { CreateAccountCoverageNoticeModalComponent } from './components/create-account-coverage-notice-modal/create-account-coverage-notice-modal.component';
import { DownloadRainCardComponent } from '@components/download-rain-card/download-rain-card.component';
import { VasRemovalDisclaimerModalComponent } from './components/vas-removal-disclaimer-modal/vas-removal-disclaimer-modal.component';
import { ConfirmContactNumberModalComponent } from './components/confirm-contact-number-modal/confirm-contact-number-modal.component';
import { UpdateContactNumberModalComponent } from './components/update-contact-number-modal/update-contact-number-modal.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { GeforceMembershipOptionsComponent } from './components/geforce-membership-options/geforce-membership-options.component';
import { GeforceMembershipModalComponent } from './components/geforce-membership-options/geforce-membership-modal/geforce-membership-modal.component';
import { GeforceOrderModalComponent } from './components/geforce-membership-options/geforce-order-modal/geforce-order-modal.component';
import { NvidiaUpsellModalComponent } from './components/geforce-membership-options/nvidia-upsell-modal/nvidia-upsell-modal.component';
import { RainOneCompetitionModalComponent } from './components/rain-one-competition-modal/rain-one-competition-modal.component';
import { Rain101Module } from './components/rain-101/rain-101.module';
import { SpeedLevelArrowComponent } from './components/speed-level-arrow/speed-level-arrow.component';
import { NvidiaOutOfCoverageModalComponent } from './components/nvidia-out-of-coverage-modal/nvidia-out-of-coverage-modal.component';
import { NvidiaUpgradeModalComponent } from './components/nvidia-upgrade-modal/nvidia-upgrade-modal.component';
import { NvidiaSoldOutModalComponent } from './components/geforce-membership-options/nvidia-sold-out-modal/nvidia-sold-out-modal.component';
import { Sa20BannerBillingComponent } from './components/sa20-banner-billing/sa20-banner-billing.component';
import { CancellationTileComponent } from './components/cancellation-tile/cancellation-tile.component';
import { ContactSupportComponent } from './components/contact-support/contact-support.component';
import { RainRainGoTileComponent } from './components/rain-rain-go-tile/rain-rain-go-tile.component';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CreateWorkAccountNoticeModalComponent } from './components/create-work-account-notice-modal/create-work-account-notice-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { CreateWorkAccountModalComponent } from './components/create-work-account-modal/create-work-account-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PaymentErrorBlockComponent } from './payment-error-block/payment-error-block.component';

const declarations = [
  BarcodeGeneratorComponent,
  SafePipe,
  AccordionComponent,
  SuccessModalComponent,
  FallbackModalComponent,
  ProductCategoryHeaderComponent,
  TotalDueNowPipe,
  RainOneLevelsComponent,
  RainOneLevelsModalComponent,
  RainOneAddPhoneLineModalComponent,
  RainOneAddWifiSpeedModalComponent,
  RainOneWifiLevelsComponent,
  BuyMoreComponent,
  RainOneBundleComponent,
  RainOneProductComponent,
  RainOneAddOnsComponent,
  QuickLinksRainOneComponent,
  QuickLinksGeneralComponent,
  CartErrorModalComponent,
  RainOneOutOfCoverageModalComponent,
  CreateAccountCoverageNoticeModalComponent,
  DownloadRainCardComponent,
  VasRemovalDisclaimerModalComponent,
  ConfirmContactNumberModalComponent,
  UpdateContactNumberModalComponent,
  GeforceMembershipOptionsComponent,
  GeforceMembershipModalComponent,
  GeforceOrderModalComponent,
  NvidiaUpsellModalComponent,
  RainOneCompetitionModalComponent,
  SpeedLevelArrowComponent,
  NvidiaOutOfCoverageModalComponent,
  NvidiaUpgradeModalComponent,
  NvidiaSoldOutModalComponent,
  Sa20BannerBillingComponent,
  CancellationTileComponent,
  ContactSupportComponent,
  RainRainGoTileComponent,
  CreateWorkAccountNoticeModalComponent,
  CreateWorkAccountModalComponent,
  PaymentErrorBlockComponent
]

@NgModule({
  declarations: [
    ...declarations
  ],
  imports: [
    CommonModule,
    NgxsModule.forFeature([UIState]),
    NgxBarcodeModule,
    RainFormsModule,
    SlickCarouselModule,
    SupportButtonModule,
    FormsModule,
    ReactiveFormsModule,
    Rain101Module,
    MatSelectModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    ...declarations,
    Rain101Module
  ]
})
export class SharedModule { }
