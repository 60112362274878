import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AccountStatusDetail, IAccountStatusDetail, IRemoteAccountStatus} from '@models/accountStatusDetail';
import {CheckoutRequest, CheckoutResponse} from '@models/checkoutRequest';
import {CACHE_AUTH_TOKEN, CACHE_IDM_TOKEN} from '@models/constants';
import {IInvoiceDetail, InvoiceDetail} from '@models/invoiceDetail';
import {ModelHelper} from '@models/modelHelper';
import {IPaymentDetail, PaymentDetail} from '@models/paymentDetail';
import {Result} from '@models/result';
import {Store} from '@ngxs/store';
import {isNil, sortBy} from 'lodash';
import moment from 'moment';
import {Observable, of} from 'rxjs';
import {catchError, map, timeout} from 'rxjs/operators';
import {BillingStoreService} from 'src/app/store/billing-store.service';
import {AuthenticationService} from '../../services/auth.service';
import {CacheService} from '../../services/cache.service';
import {ConfigService} from '../../services/config.service';
import {OrderService} from '../../services/order.service';
import {ServiceHelper} from '../../services/serviceHelper';
import {TokenService} from '../../services/token.service';
import {ServicesState} from '../../store/state/services.state';
import {BillCyclePayload} from '../interfaces/bill-cycle-payload.interface';
import {SetSavedPaymentDate} from '../store/actions/billing.actions';
import {AuthState} from '../store/state/auth.state';
import {UIState} from 'src/app/shared/store/state/ui.state';
import {ToastrService} from "ngx-toastr";

/**
 * ERROR_NO_SV @param
 */
export const ERROR_NO_SV = 'No SV account found';

/**
 * ERROR_PAYMENT_PENDING @param
 */
export const ERROR_PAYMENT_PENDING = '00022';

/**
 * IRemotePayNowResponse @param
 */
export interface IRemotePayNowResponse {
    lastInvoiceDate: string;
    invoiceId: string;
    invoiceAmount: string;
    accountBalance: string;
    success: boolean;
}

/**
 * IRemoteBillRunResponseList  @param
 */
export interface IRemoteBillRunResponseList {
    billNumber: string;
    billStatus: string;
    billAmount: string;
    billAmountDue: string;
    currentBalance: string;
    billDate: string;
    paymentDueDate: string;
    billType: string;
}

/**
 * IRemoteBill @param
 */
export interface IRemoteBill {
    accountNumber: string;
    emailAddress: string;
    payNowResponse: IRemotePayNowResponse;
    billRunResponseList: IRemoteBillRunResponseList[];
}

/**
 * IBillHistory @param
 */
export interface IBillHistory {
    date: Date;
    name: string;
    amount: number;
}

/**
 *  IBillDetail @param
 */
export interface IBillDetail {
    history: IBillHistory[];
    accountNumber?: string;
}

/**
 * BillDetail @param
 */
export class BillDetail {
    static adapt(remote: IRemoteBill): IBillDetail {
        let history: IBillHistory[] = remote.billRunResponseList.map(
            x =>
                ({
                    date: ModelHelper.toDate(x.billDate),
                    name: x.billType,
                    amount: ModelHelper.toFloat(x.billAmount)
                } as IBillHistory)
        );

        history = sortBy(history, ['date']).reverse();

        return {
            history,
            accountNumber: remote.accountNumber
        } as IBillDetail;
    }
}

/**
 * IPaymentStatusDetail @param
 */
export interface IPaymentStatusDetail {
    completed: boolean;
    checkout: string;
    message: string;
    status: PaymentStatuses;
}

/**
 * PaymentStatuses @param
 */
export enum PaymentStatuses {
    None = 0,
    Failed,
    Success,
    Pending,
    Timeout
}

/**
 * PaymentStatusDetail @param
 */
export class PaymentStatusDetail {
    static adapt(remote: any): IPaymentStatusDetail {
        const result = {
            checkout: ModelHelper.clean(remote.checkout),
            message: ModelHelper.clean(remote.message),
            completed: remote.completed,
            status: this.mapStatus(remote.status)
        } as IPaymentStatusDetail;

        return result;
    }

    static mapStatus(status: string): PaymentStatuses {
        switch (status) {
            case 'failed':
                return PaymentStatuses.Failed;
            case 'success':
                return PaymentStatuses.Success;
            case 'pending':
                return PaymentStatuses.Pending;
            default:
                return PaymentStatuses.None;
        }
    }
}

/**

 * @export
 * @class BillingService
 */
@Injectable({
    providedIn: 'root'
})
export class BillingService {
    constructor(
        private configService: ConfigService,
        private http: HttpClient,
        private tokenService: TokenService,
        private cacheService: CacheService,
        private authService: AuthenticationService,
        private billingStore: BillingStoreService,
        private orderService: OrderService,
        private store: Store,
        private toastr: ToastrService,
    ) {
    }

    public getInvoices(): Observable<Result<IInvoiceDetail[]>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }
        const requestPath = `${this.configService.API_URL}/billing/bill/`;


        let userMode = this.store.selectSnapshot(UIState.GetUIMode)
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)

        let userToken = (userMode === 'consumer' || userMode === 'mobile')
            ? this.tokenService.get(CACHE_AUTH_TOKEN)
            : smeToken;
        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: userToken
            })
        };

        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((result: any) => {
                const invoices = result.map(x => {
                    return InvoiceDetail.adapt(x);
                });

                this.cacheService.setObject('invoices', invoices);

                return Result.success(invoices);
            }),
            catchError(result => {
                if (result.error.error == ERROR_NO_SV) {
                    return of(Result.notFound<IInvoiceDetail[]>());
                }
                return ServiceHelper.handleError<IInvoiceDetail[]>(result);
            })
        );
    }

    public getBill() {
        const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);

        const requestPath = `${this.configService.AXIOM_IDM_URL}/account/getbill`;

        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: 'Bearer ' + idmToken
            })
        };

        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((remote: IRemoteBill) => {
                return Result.success(BillDetail.adapt(remote));
            }),
            catchError(result => ServiceHelper.handleError<BillDetail>(result))
        );
    }

  public getInvoice(billingNumber: string): Observable<Result<IInvoiceDetail>> {
    if (this.authService.isSignedIn === false) {
      return of(Result.error('Not Signed In'));
    }

        const tokenCredential = this.store.selectSnapshot(AuthState.getTokens)?.tokenCredential;
        const requestPath = `https://prod-bss-api.rain.co.za/axiom/rain-go/billing/download-invoice/${billingNumber}`;
      
        const httpOptions = {
            headers: new HttpHeaders({
              Authorization: tokenCredential
            }),
            responseType: 'blob' as const // Expect PDF
          };

          return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((blob: Blob) => {
              const url = window.URL.createObjectURL(blob);
              window.open(url, '_blank');
              return Result.error<IInvoiceDetail>('PDF response received instead of JSON');
            }),
            catchError(error => {
              console.error('Failed to fetch invoice:', error);
              return of(Result.error<IInvoiceDetail>('Failed to fetch invoice'));
            })
          );
        }

    public getActivePaymentMethod(): Observable<Result<IPaymentDetail>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }

        let userMode = this.store.selectSnapshot(UIState.GetUIMode);
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken);

        let userToken = ((userMode !== 'consumer' && smeToken)) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);

        const requestPath = `${this.configService.API_URL}/billing/payment_method`;
        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: userToken
            })
        };

        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((result: any) => {
                const payDetail = PaymentDetail.adapt(result);
                if (isNil(payDetail.id)) {
                    return Result.notFound<IPaymentDetail>();
                }

                return Result.success(payDetail);
            }),
            catchError(result => ServiceHelper.handleError<IPaymentDetail>(result))
        );
    }

    public GetLastFailedPayment(): Observable<any> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }
        const userId = this.store.selectSnapshot(AuthState.getUserIdFromTokenCredential);
        const requestPath = `${this.configService.BASE_API_URL}/axiom/payment/findReason/${userId}`;
        const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);

        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: 'Bearer ' + idmToken
            })
        };

        return this.http.get<any>(requestPath, httpOptions)
    }

    public getAccountStatus(email: string): Observable<Result<IAccountStatusDetail>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }

        const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);
        const requestPath = `${this.configService.BASE_API_URL}/v1/bss/account/getstatus`;

        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: 'Bearer ' + idmToken
            })
        };

        const body = {
            email
        };
        return this.http.post(requestPath, body, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((result: IRemoteAccountStatus) => {
                this.billingStore.account = result;
                const accountState = AccountStatusDetail.adapt(result);
                const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);
                if (accountState.currentPayDate && isPostPaid) {
                    this.store.dispatch(new SetSavedPaymentDate({
                        value: accountState.currentPayDate,
                        type: 'PaymentDate'
                    }))
                }

                return Result.success(accountState);
            }),
            catchError(result => ServiceHelper.handleError<IAccountStatusDetail>(result))
        );
    }

    public getRegisterCheckoutId(): Observable<Result<CheckoutResponse>> {
        return this.getCheckoutId({
            type: 'register'
        });
    }

    public getOrderCheckoutId(orderId: string, amount: number): Observable<Result<CheckoutResponse>> {
        const amountStr = (Math.round(amount * 100) / 100).toFixed(2);

        let type = 'register_and_pay';

        return this.getCheckoutId({
            amount: amountStr,
            order_id: orderId,
            type
        });
    }

    public getPrePaidCheckoutId(serviceId: string, amount: number): Observable<Result<CheckoutResponse>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }

        let userMode = this.store.selectSnapshot(UIState.GetUIMode)
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)

        let userToken = ((userMode !== 'consumer') && smeToken) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN)

        const requestPath = `${this.configService.BASE_API_URL}/v1/bss-pg/payments/checkout`;

        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'qidSQZHGqFTShfuCksIZJ74aDwLpWEw5';

        let returnBaseURL = '';
        if (domain.includes('sit')) {
            returnBaseURL = 'https://sit.precipitation.co.za';
        } else if (domain.includes('precipitation')) {
            returnBaseURL = 'https://precipitation.co.za';
        } else {
            returnBaseURL = 'https://www.rain.co.za';
        }

        let request = {
            amount,
            serviceId,
            channel: 'topup',
            successUrl: `${returnBaseURL}/giveagig/payment-result?somedata=true`,
            failedUrl: `${returnBaseURL}/giveagig/payment-result?otherdata=true`,
            cancelledUrl: `${returnBaseURL}/giveagig/payment-result?nodata=true`
        };


        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: userToken
            })
        };

        return this.http.post(requestPath, request, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((result: any) => {
                // const checkout = CheckoutResponse.adapt(result); //TODO - to ammend the interface

                return Result.success(result);
            }),
            catchError(result => {
                if (result.error && result.error.code == ERROR_PAYMENT_PENDING) {
                    return of(Result.error<CheckoutResponse>('There is already a payment pending for this transaction.'));
                }

                return ServiceHelper.handleError<CheckoutResponse>(result);
            })
        );
    }

    public getArrearsCheckoutId(
        invoiceId: string,
        amount: number,
        payNowPartialPaymentType: string,
        billingDetails: any
    ): Observable<Result<CheckoutResponse>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }

        const requestPath = `${this.configService.BASE_API_URL}/v1/bss-pg/payments/checkout`;

        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'qidSQZHGqFTShfuCksIZJ74aDwLpWEw5';

        let returnBaseURL = '';
        if (domain.includes('sit')) {
            returnBaseURL = 'https://sit.precipitation.co.za';
        } else if (domain.includes('precipitation')) {
            returnBaseURL = 'https://precipitation.co.za';
        } else {
            returnBaseURL = 'https://www.rain.co.za';
        }

        let request = {
            amount,
            invoiceId: invoiceId,
            successUrl: `${returnBaseURL}/arrears-payment-status?somedata=true`,
            failedUrl: `${returnBaseURL}/arrears-payment-status?otherdata=true`,
            cancelledUrl: `${returnBaseURL}/arrears-payment-status?nodata=true`
        };

        if (billingDetails.account.accountState === 'FUTUREPAYRUN') {
            request['channel'] = 'paynow';
        } else {
            if (payNowPartialPaymentType === 'minimumPayment') {
                request['channel'] = 'partial';
            } else {
                if (
                    billingDetails.account.accountState === 'OVERDUE' ||
                    billingDetails.account.accountState === 'GRACE' ||
                    billingDetails.account.accountState === 'OVERDUEHIGHSPEED'
                ) {
                    const today = new Date();
                    let todayDay = moment().format('DD');
                    todayDay = todayDay.replace(/^0+/, '');
                    if (+todayDay >= +billingDetails.paydate) {
                        request['channel'] = 'paynow';
                    } else {
                        request['channel'] = 'arrears';
                    }
                } else {
                    request['channel'] = 'arrears';
                }
            }
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: this.tokenService.getTokenForAccountType()
            })
        };

        return this.http.post(requestPath, request, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((result: any) => {
                // const checkout = CheckoutResponse.adapt(result); //TODO - to ammend the interface

                return Result.success(result);
            }),
            catchError(result => {
                if (result.error && result.error.code == ERROR_PAYMENT_PENDING) {
                    return of(Result.error<CheckoutResponse>('There is already a payment pending for this transaction.'));
                }

                return ServiceHelper.handleError<CheckoutResponse>(result);
            })
        );
    }

    public getPaymentStatus(paymentId: string): Observable<Result<IPaymentStatusDetail>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }

        let userMode = this.store.selectSnapshot(UIState.GetUIMode)
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)

        let userToken = ((userMode !== 'consumer')) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN)

        const requestPath = `${this.configService.BASE_API_URL}/v1/bss-pg/payments/${paymentId}/status`;

        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'qidSQZHGqFTShfuCksIZJ74aDwLpWEw5';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: userToken
            })
        };

        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((result: any) => {
                const checkout = PaymentStatusDetail.adapt(result);
                return Result.success(checkout);
            }),
            catchError(result => ServiceHelper.handleError<IPaymentStatusDetail>(result))
        );
    }

    public getCheckoutId(request: CheckoutRequest): Observable<Result<CheckoutResponse>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }

        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const requestPath = `${this.configService.API_URL}/billing/checkout`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: this.tokenService.getTokenForAccountType()
            })
        };

        return this.http.post(requestPath, request, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((result: any) => {
                const checkout = CheckoutResponse.adapt(result);

                return Result.success(checkout);
            }),
            catchError(result => ServiceHelper.handleError<CheckoutResponse>(result))
        );
    }

    public checkPaymentStatus(paymentId: string): Observable<Result<CheckoutResponse>> {
        return this.getCheckoutId({
            id: paymentId,
            type: 'status'
        });
    }

    public payLater(email: string): Observable<Result<boolean>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }

        const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);

        const requestPath = `${this.configService.AXIOM_IDM_URL}/account/status`;
        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: 'Bearer ' + idmToken
            })
        };

        const body = {
            action: 'ptp',
            email: email
        };

        return this.http.post(requestPath, body, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map(remote => {
                return Result.success(true);
            }),
            catchError(result => ServiceHelper.handleError<boolean>(result))
        );
    }

    public setCycleOption(payload: BillCyclePayload, token?: string): Observable<any> {
        const url = `${this.configService.BILLING_CYCLE_API}/set-billcycle`;
        let userMode = this.store.selectSnapshot(UIState.GetUIMode);
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken);

        let userToken = ((userMode !== 'consumer' && smeToken)) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: token ? token : userToken
            })
        };
        return this.http.post(url, payload, httpOptions);

    }

    public SetProRataData(email: string) {
        const url = `${this.configService.BILLING_CYCLE_API}/pro-rata-data`;
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)
        let userToken

        if (smeToken) {
            userToken = smeToken
        } else {
            userToken = this.tokenService.get(CACHE_AUTH_TOKEN)
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userToken,
                'email': email
            })
        };

        return this.http.post(url, {}, httpOptions);
    }
}
