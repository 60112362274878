import {Injectable} from "@angular/core";
import {Result} from "@models/result";
import {CACHE_AUTH_TOKEN, CONFIG_SPEND_LIMIT} from "@models/constants";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map, timeout} from "rxjs/operators";
import {TokenService} from "@services/token.service";
import {ConfigService} from "@services/config.service";
import {Observable, of} from "rxjs";
import {NotificationCategories, NotificationChannels} from "@pages/notifications/models/inbox.models";
import {UserUpdateRequest} from "@models/userUpdateRequest";
import {ServiceHelper} from "@services/serviceHelper";
import {ToastService} from "../../utils/ToastService";
import {CoreState} from "../../../core/store/state/core.state";
import {returnUserIdFromTokens} from "../../../utils/helpers";
import {AxiomDigitalIdentityUserDetail} from "../../../shared/interfaces/axiom/axiom.interfaces";
import {Store} from "@ngxs/store";
import {UIState} from "../../../shared/store/state/ui.state";
import {AuthState} from "../../../core/store/state/auth.state";

@Injectable({
    providedIn: 'root'
})
export class UserServiceV2 {

    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        private configService: ConfigService,
        private toastService: ToastService,
        private store: Store
    ) {
    }

    public getUserDetails(): Observable<any> {
        const apiURl = this.configService.API_URL_V5;
        const requestPath = `${apiURl}/profile_summary`;
        let userToken = this.tokenService.getTokenForAccountType();
        let key = this.configService.KEY;
        let apiKey = this.configService.API_KEY;

        // all of these headers and tokens needs to move to an interceptor
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: userToken,
                key: key,
                apiKey: apiKey
            })
        };

        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((res: any) => {
                return Result.success(res);
            })
        );
    }

    public getUserPermissions(userId: string): Observable<any> {
        const apiURl = this.configService.AXIOM_URL;
        const requestPath = `${apiURl}/party-role-management/partyRole/list/${userId}`;
        let userToken = this.tokenService.get(CACHE_AUTH_TOKEN);
        let key = this.configService.KEY;
        let apiKey = this.configService.API_KEY;

        // all of these headers and tokens needs to move to an interceptor
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: userToken,
                key: key,
                apiKey: apiKey
            })
        };

        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((res: any) => {
                return Result.success(res);
            })
        );
    }

    getOptInChannelDetails() {
        const requestPath = `${this.configService.CUSTOMER_CONSENT}/consent/channel`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
            })
        };

        return this.http.get(requestPath, httpOptions);
    }

    optInChannel(accepted: boolean) {
        const data = {
            channelId: NotificationChannels.WHATSAPP,
            accepted: accepted
        };
        const requestPath = `${this.configService.CUSTOMER_CONSENT}/consent/channel`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
            })
        };

        return this.http.post(requestPath, data, httpOptions);
    }

    optInCategory(accepted: boolean) {
        const data = {
            categoryId: NotificationCategories.PROMO,
            accepted: accepted
        };
        const requestPath = `${this.configService.CUSTOMER_CONSENT}/consent/category`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
            })
        };

        return this.http.post(requestPath, data, httpOptions);
    }

    getOptInCategoryDetails() {
        const requestPath = `${this.configService.CUSTOMER_CONSENT}/consent/category`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.tokenService.get(CACHE_AUTH_TOKEN)
            })
        };

        return this.http.get(requestPath, httpOptions);
    }

    setServiceDetails(serviceId: string, body: any) {
        let userId = this.store.selectSnapshot(CoreState.getUserProfile)?.id
        const requestPath = `${this.configService.BASE_API_URL}/bss-api/v1/service-proxy/fullcircle/service/${serviceId}`;

        let uiMode = this.store.selectSnapshot(UIState.GetUIMode);
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken);

        let userToken = ((uiMode !== 'consumer') && smeToken) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: userToken,
                userId: userId
            })
        };

        return this.http.patch(requestPath, body, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((response: any) => {
                this.toastService.showToast('details updated successfully', 'success');
                return response;
            }),
            catchError(result => {
                this.toastService.showToast('failed to update details', 'error');
                return ServiceHelper.handleError<boolean>(result)
            })
        );
    }

    setLegacy4GLimit(serviceId: string, value: string) {

        const configs = [];

        configs.push({name: 'action', value: 'migrate'});
        configs.push({name: CONFIG_SPEND_LIMIT, value});

        const body: any = {
            product_configs: {configs}
        };

        return this.setServiceDetails(serviceId, body);
    }

    getAxiomUserDetails() {

        const tokens = this.store.selectSnapshot(CoreState.getTokens);

        if (tokens) {
            const userId = returnUserIdFromTokens(tokens);

            const httpAuthHeader = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokens?.idm}`
                })
            }
            const url = `${this.configService.AXIOM_URL}/digital-identity/digitalIdentity/${userId}`;
            return this.http.get<AxiomDigitalIdentityUserDetail>(url, httpAuthHeader);
        }
    }

    //TODO remove when axiom endpoint is ready
    public updateUserDetailsOld(request: UserUpdateRequest, token?: string): Observable<Result<boolean>> {
        const requestPath = `${this.configService.API_URL}/users/${request.id}`;
        
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: this.tokenService.getTokenForAccountType()
            })
        };

        let dirty = false;

        const user: any = {};

        if (request.address) {
            user.ricaAddress = {
                street_number: request.address.streetNumber,
                street_name: request.address.streetName,
                building_name: request.address.buildingName,
                floor_level: request.address.floorLevel,
                unit_number: request.address.unitNumber,
                suburb: request.address.suburb,
                city: request.address.city,
                province: request.address.province,
                postal_code: request.address.postalCode
            };

            dirty = true;
        }

        if (request.firstName) {
            user.first_name = request.firstName;
            dirty = true;
        }

        if (request.lastName) {
            user.last_name = request.lastName;
            dirty = true;
        }

        if (request.email) {
            user.email = request.email;
            dirty = true;
        }

        if (request.phone) {
            user.phone = request.phone;
            dirty = true;
        }

        if (request.idNumber) {
            user.id_number = request.idNumber;
            dirty = true;
        }

        if (request.company_name) {
            user.company_name = request.company_name;
            dirty = true;
        }

        if (request.company_number) {
            user.company_number = request.company_number;
            dirty = true;
        }
        if (request.vat_number) {
            user.vat_number = request.vat_number;
            dirty = true;
        }

        if (dirty === false) {
            return of(Result.error('Nothing to update'));
        }

        const body = {
            user
        };

        return this.http.patch(requestPath, body, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((_: any) => {
                this.toastService.showToast('details updated successfully', 'success');
                return Result.success(true);
            }),
            catchError(result => {
                this.toastService.showToast('failed to update details', 'error');
                return ServiceHelper.handleError<boolean>(result)
            })
        );
    }
}