import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAddress} from '@models/addressDetail';
import {
    CACHE_AUTH_TOKEN,
    CACHE_LOGISTIC,
    CACHE_ORDERS,
    CACHE_SERVICES,
    CACHE_SME_TOKEN,
    CONFIG_VALUE_ADDS,
    DEFAULT_POSTAL_CODE
} from '@models/constants';
import {IDictionary} from '@models/dictionary';
import {DeliveryType} from '@models/logisticDetail';
import {IOrderDetail, OrderDetail} from '@models/orderDetail';
import {IProductDetail} from '@models/productDetail';
import {IRemoteOrder} from '@models/remote/remoteOrder';
import {Result, Statuses} from '@models/result';
import {Store} from '@ngxs/store';
import {every, isEmpty, isNil} from 'lodash';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {catchError, map, switchMap, timeout} from 'rxjs/operators';
import {PostUserCoverageAddress} from '../core/store/actions/coverage.actions';
import {VoucherState} from '../core/store/state/voucher.state';
import {SetOrders} from '../store/actions/order.actions';
import {AuthenticationService} from './auth.service';
import {CacheService} from './cache.service';
import {ConfigService} from './config.service';
import {IdleService} from './idle.service';
import {ProductService} from './product.service';
import {ServiceHelper} from './serviceHelper';
import {TokenService} from './token.service';
import {CartState} from '../store/state/cart.state';
import {UIState} from '../shared/store/state/ui.state';
import {AuthState} from '../core/store/state/auth.state';
import { CoverageState } from '../core/store/state/coverage.state';

/**
 * CACHE_EXPIRY @param {number}
 */
const CACHE_EXPIRY: number = 600000;

/**
 * OrderAddRequest @param
 */
export class OrderAddRequest {
    deliveryAddress: IAddress;
    deliveryType: string;
    ricaType: string;
    items: IOrderAddItem[];
    instructions: string;
}

/**
 * IOrderAddItem @param
 */
export interface IOrderAddItem {
    productId: string;
    config: IDictionary<any>;
    delivery?: IDictionary<any>;
}

/**
 *
 *
 * @export
 * @class OrderService
 */
@Injectable({
    providedIn: 'root'
})
export class OrderService {
    constructor(
        private configService: ConfigService,
        private http: HttpClient,
        private tokenService: TokenService,
        private cacheService: CacheService,
        private authService: AuthenticationService,
        private productService: ProductService,
        private idleService: IdleService,
        private store: Store
    ) {
    }

    public onUpdated: Subject<string> = new Subject<string>();

    public cancel(orderId: string): Observable<Result<boolean>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }
        let uiMode = this.store.selectSnapshot(UIState.GetUIMode)
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken) || this.tokenService.get(CACHE_SME_TOKEN);
        let orderToken = (uiMode === 'sme' && smeToken) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN)
        const requestPath = `${this.configService.API_URL}/order/${orderId}/cancel`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: orderToken
            })
        };

        return this.http.delete(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((_: any) => {
                this.clearCache(orderId);
                this.onUpdated.next(orderId);
                this.cacheService.remove(CACHE_SERVICES);
                return Result.success(true);
            }),
            catchError(result => ServiceHelper.handleError<boolean>(result))
        );
    }


    public add(request: OrderAddRequest, addons?): Observable<Result<IOrderDetail>> {
        let uiMode = this.store.selectSnapshot(UIState.GetUIMode);
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken) || this.tokenService.get(CACHE_SME_TOKEN);
        let orderToken = (uiMode === 'sme' && smeToken) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);
        const orderType = this.store.selectSnapshot(CartState.GetOrderType);
        const isMobileOrder = orderType === "mobile";
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }
        const accessories = isMobileOrder ? [] : this.store.selectSnapshot(CartState.GetAccessories).map((a) => {
            return {
                config: a.config,
                productId: a.id
            }
        });
        const items = addons ? [...request.items, ...addons, ...accessories] : [...request.items, ...accessories];
        const productIds = items.filter((x: any) => isNil(x.productId) == false).map(x => x.productId);

        if (isEmpty(productIds)) {
            return of(Result.error('No valid productIds specified'));
        }

        const observables = productIds.map(id => this.productService.getById(id));
        const coverageAddress = this.store.selectSnapshot(CoverageState.getUserAddress);

        return forkJoin(observables).pipe(
            map((productDetailResults: Result<IProductDetail>[]) => {
                if (every(productDetailResults, {status: Statuses.Success})) {
                    const productDetails = productDetailResults.map(x => x.value);
                    let orderLines = items.map(x => {
                        const productDetail = productDetails.find(y => y.id == x.productId);
                        return {
                            product_id: productDetail.id,
                            number: 0,
                            quantity: 1,
                            product_configs: this.mapProductConfigs(x)
                        };
                    });

                    let storageDeliveryConfigs: Array<{ name: string, value: string }> = null
                    if (localStorage.getItem('deliveryConfigs')) {
                        storageDeliveryConfigs = JSON.parse(localStorage.getItem('deliveryConfigs'));
                        const method = storageDeliveryConfigs.find((i) => i.name === 'courier_method' && i.value === 'Pickup');
                        if (method) {
                            orderLines[0].product_configs.delivery = storageDeliveryConfigs as any;
                        }
                    }

                    let orderDeliveryType = request.deliveryType;
                    const isRainCourier = orderLines[0].product_configs.delivery.some(x => x.value === 'RAIN');
                    let rainCourierDeliveryConfigs
                    if (isRainCourier) {
                        rainCourierDeliveryConfigs = orderLines[0].product_configs.delivery;
                        if (rainCourierDeliveryConfigs) {
                            const deliveryType = rainCourierDeliveryConfigs.find(x => x.name === 'courier_method');
                            if (deliveryType.value === 'Pickup') {
                                orderDeliveryType = DeliveryType.Collection;
                            }
                        }
                    }

                    const isValidVoucher = this.store.selectSnapshot(VoucherState.isValid);
                    const orderType = this.store.selectSnapshot(CartState.GetOrderType)

                    const body = {
                        delivery: {
                            instructions: isNil(request.instructions) ? '' : request.instructions,
                            time: null,
                            type: orderDeliveryType,
                            rica_type: request.ricaType,
                            sales_channel: 'RAIN WEB'
                        },
                        deliveryAddress: {
                            building_name: '',
                            city: '',
                            floor_level: '',
                            postal_code: DEFAULT_POSTAL_CODE,
                            province: '',
                            street_name: '',
                            suburb: '',
                            unit_number: '',
                            street_number: '',
                            gps_coordinates: {
                                longitude: '',
                                latitude: ''
                            }
                        },
                        order_lines: orderLines,
                        comments: '',
                        comment: '',
                    };
                    let comments = {};
                    let comment = {};

                    if (isValidVoucher && (orderType === 'bundle' || orderType === 'multi' || orderType === 'nvidia')) {
                        comments['voucher'] = this.store.selectSnapshot(VoucherState.getVoucherCode);
                        comments['invoiceText'] = 'discount black friday';
                    }

                    comments['isIn5G'] = JSON.parse(localStorage.getItem('isIn5G'));
                    comments['location_id'] = orderDeliveryType === DeliveryType.Collection ? rainCourierDeliveryConfigs.find(x => x.name === 'courier_method')?.locationId : '';
                    comments['coverage_address'] = coverageAddress;

                    body.comments = JSON.stringify(comments);
                    body.comment = JSON.stringify(comment);

                    if (isNil(request.deliveryAddress) == false) {
                        body.deliveryAddress = {
                            building_name: isNil(request.deliveryAddress.buildingName) ? '' : request.deliveryAddress.buildingName,
                            city: isNil(request.deliveryAddress.city) ? '' : request.deliveryAddress.city,
                            floor_level: isNil(request.deliveryAddress.floorLevel) ? '' : request.deliveryAddress.floorLevel,
                            postal_code: isNil(request.deliveryAddress.postalCode)
                                ? DEFAULT_POSTAL_CODE
                                : request.deliveryAddress.postalCode,
                            province: isNil(request.deliveryAddress.province) ? '' : request.deliveryAddress.province,
                            street_name: isNil(request.deliveryAddress.streetName) ? '' : request.deliveryAddress.streetName,
                            suburb: isNil(request.deliveryAddress.suburb) ? '' : request.deliveryAddress.suburb,
                            street_number: isNil(request.deliveryAddress.streetNumber) ? '' : request.deliveryAddress.streetNumber,
                            unit_number: isNil(request.deliveryAddress.unitNumber) ? '' : request.deliveryAddress.unitNumber,
                            gps_coordinates: {
                                longitude: request.deliveryAddress.gps_coordinates?.longitude || '',
                                latitude: request.deliveryAddress.gps_coordinates?.latitude || ''
                            }
                        };
                    }
                 return body;
                }
                
                throw new Error('Not all products was succesfull.');
            }),
            switchMap(body => {
                const requestPath = `${this.configService.BASE_API_URL}/V1/rain-web/order-proxy/order`;

                const httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + orderToken
                    })
                };

                // return this.http.post(requestPath, body, httpOptions);
                return this.http.post(requestPath, body, httpOptions);
            }),
            map((response: any) => {


                if (response.error) {
                    throw new Error(response.error);
                }
                if (localStorage.getItem('two_for_one')) {
                    const twoForOneOL = response.order_lines.find((l) => l.product_id === '12bf79dc-f05b-46cf-a91c-d28b79ed84e1');
                    localStorage.setItem('two_for_one_redeem_ol', twoForOneOL.id);
                }
                const orderDetail = OrderDetail.adapt(response);
                this.store.dispatch(new PostUserCoverageAddress(orderDetail.id));
                this.cacheOrder(orderDetail);

                this.clearCache(orderDetail.id);

                return Result.success(orderDetail);
            }),
            catchError(result => ServiceHelper.handleError<IOrderDetail>(result))
        );
    }

    private mapProductConfigs(orderline: IOrderAddItem) {
        const productConfigs = {
            configs: [],
            delivery: []
        };

        for (const key in orderline.config) {
            const element = orderline.config[key];

            if (key === CONFIG_VALUE_ADDS) {
                productConfigs[CONFIG_VALUE_ADDS] = orderline.config[CONFIG_VALUE_ADDS];
            } else {
                if (element !== 'Delivery') {
                    productConfigs.configs.push({value: element, name: key});
                }

            }
        }

        return productConfigs;
    }

    public createPrePaidOrder(request: any): Observable<Result<any>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }

        const requestPath = `${this.configService.API_URL}/order`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
            })
        };

        return this.http.post(requestPath, request, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((response: any) => {

                return Result.success(response);
            }),
            catchError(result => ServiceHelper.handleError<IOrderDetail>(result))
        );
    }

    public generateVoucher(request: any): Observable<Result<any>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }

        const requestPath = `${this.configService.VOUCHER}/generate-voucher`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
            })
        };

        return this.http.post(requestPath, request, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((response: any) => {

                return Result.success(response);
            }),
            catchError(result => ServiceHelper.handleError<IOrderDetail>(result))
        );
    }

    public redeemVoucher(request: any): Observable<Result<any>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }

        const requestPath = `${this.configService.VOUCHER}/redeem-voucher`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
            })
        };

        return this.http.post(requestPath, request, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((response: any) => {

                return Result.success(response);
            }),
            catchError(result => ServiceHelper.handleError<IOrderDetail>(result))
        );
    }

    public get(id: string, ignoreCache = true): Observable<Result<IOrderDetail>> {
        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }
        let uiMode = this.store.selectSnapshot(UIState.GetUIMode)
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken) || this.tokenService.get(CACHE_SME_TOKEN);
        let orderToken = (uiMode === 'sme' && smeToken) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN)


        if (ignoreCache === false && this.isOrderCached(id)) {
            const orderDetail = this.getCacheItem(id);

            this.idleService.resetTimer();

            const result = Result.success(orderDetail);

            return of(result);
        }

        const requestPath = `${this.configService.API_URL}/order/${id}`;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: orderToken
            })
        };

        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((remoteOrder: any) => {

                const orderDetail = OrderDetail.adapt(remoteOrder);

                this.cacheOrder(orderDetail);

                return Result.success(orderDetail);
            }),
            catchError(result => ServiceHelper.handleError<IOrderDetail>(result))
        );
    }

    public getAllSmeOrders(ignoreCache = true, token?: string): Observable<Result<IOrderDetail[]>> {

        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken) || this.tokenService.get(CACHE_SME_TOKEN);

        if (!smeToken) {
            return of(Result.error('Not Signed In'));
        }

        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }
        if (smeToken) {
            const requestPath = `${this.configService.API_URL}/orders`;
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: smeToken
                })
            };

            return this.http.get(requestPath, httpOptions).pipe(
                timeout(this.configService.API_TIMEOUT),
                map((result: { orders: IRemoteOrder[] }) => {
                    const orders = result.orders;
                    const orderDetails = orders.map<IOrderDetail>(remoteOrder => OrderDetail.adapt(remoteOrder));
                    this.store.dispatch(new SetOrders(orderDetails));
                    //this.cacheOrders(orderDetails);
                    return Result.success(orderDetails);
                }),
                catchError(result => ServiceHelper.handleError<IOrderDetail[]>(result))
            );
        }
        if (ignoreCache === false && this.isOrdersCached()) {
            const orderDetails = this.getCache();

            this.idleService.resetTimer();

            const result = Result.success(orderDetails);

            return of(result);
        }

    }

    public getAll(ignoreCache = true, token?: string): Observable<Result<IOrderDetail[]>> {

        if (this.authService.isSignedIn === false) {
            return of(Result.error('Not Signed In'));
        }

        if (ignoreCache === false && this.isOrdersCached()) {
            const orderDetails = this.getCache();

            this.idleService.resetTimer();

            const result = Result.success(orderDetails);

            return of(result);
        }
        const requestPath = `${this.configService.API_URL}/orders`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: this.tokenService.get(CACHE_AUTH_TOKEN)
            })
        };

        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((result: { orders: IRemoteOrder[] }) => {
                const orders = result.orders;
                const orderDetails = orders.map<IOrderDetail>(remoteOrder => OrderDetail.adapt(remoteOrder));
                this.store.dispatch(new SetOrders(orderDetails));
                this.cacheOrders(orderDetails);
                return Result.success(orderDetails);
            }),
            catchError(result => ServiceHelper.handleError<IOrderDetail[]>(result))
        );
    }

    clearOrdersCache() {
        this.cacheService.remove(CACHE_ORDERS);
    }

    private removeCacheOrder(orderId: string) {
        this.cacheService.remove(this.getSingleKey(orderId));
    }

    private cacheOrders(orders: IOrderDetail[]) {
        this.cacheService.setObject(CACHE_ORDERS, orders, CACHE_EXPIRY);
    }

    private cacheOrder(orderDetail: IOrderDetail) {
        this.cacheService.setObject(this.getSingleKey(orderDetail.id), orderDetail, CACHE_EXPIRY);
    }

    private isOrdersCached(): boolean {
        return this.cacheService.exists(CACHE_ORDERS);
    }

    private isOrderCached(id: string): boolean {
        return this.cacheService.exists(this.getSingleKey(id));
    }

    private getCache(): IOrderDetail[] {
        return this.cacheService.getObject(CACHE_ORDERS);
    }

    private getCacheItem(id: string): IOrderDetail {
        return this.cacheService.getObject(this.getSingleKey(id));
    }

    private getSingleKey(id: string) {
        return `order_${id}`;
    }

    private clearCache(orderId: string) {
        this.removeCacheOrder(orderId);
        this.clearOrdersCache();
        this.cacheService.remove(CACHE_LOGISTIC);
        this.onUpdated.next();
    }

    public clearOrder(orderId: string) {
        const orderKey = this.getSingleKey(orderId);
        this.cacheService.remove(orderKey);
        this.onUpdated.next();
    }

    public createRainOneOrder() {

    }
}
