import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ElementService} from '@services/element.service';
import {
    a30Id,
    CONFIG_COMMENTS,
    CONFIG_FRIENDLY_NAME,
    DEFAULT_LATITUDE,
    DEFAULT_LONGITUDE,
    NO_MORE_RAIN_MOBILE_MESSAGE,
    NO_MORE_RAIN_ONE_MESSAGE,
    NO_MORE_RAIN_ONE_WORK_MESSAGE,
    NUMBER_REPLACE,
    pro1001ExtId,
    pro1002ExtId,
    pro100NoExtId,
    pro601ExtId,
    pro602ExtId,
    pro60NoExtId,
    proWork1001ExtId,
    proWork1002ExtId,
    proWork100NoExtId,
    proWork601ExtId,
    proWork602ExtId,
    proWork60NoExtId,
    RAIN_ONE_LEVELS,
    RAINONE_101_5G_SIM,
    RAINONE_SME_IDS,
    work30Id
} from '@models/constants';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../baseComponent';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {VoucherActivateModalComponent} from '@components/voucher-activate-modal/voucher-activate-modal.component';
import {CustomerRatingModalComponent} from '@components/customer-rating-modal/customer-rating-modal.component';
import {Select, Store} from '@ngxs/store';
import {CoreState} from 'src/app/core/store/state/core.state';
import {Observable, Subject} from 'rxjs';
import {ProductState} from 'src/app/store/state/product.state';
import {ImageViewModel} from '../../shared/selectors/image-view-model';
import {Navigate} from '@ngxs/router-plugin';
import {SalesModalComponent} from '@components/sales-modal/sales-modal.component';
import {
    RainOneModalVideoComponent
} from 'src/app/shared/components/rain-101/rain-one-modal-video/rain-one-modal-video.component';
import {FirebaseConfigsState} from 'src/app/core/store/state/firebase-configs.state';
import {RainFlags} from 'src/app/core/interfaces/rain-flags.interface';
import {UIMode, UIState} from 'src/app/shared/store/state/ui.state';
import {UIActions} from 'src/app/shared/store/actions/ui.actions';
import {
    SalesUpgradeModalComponent
} from '@components/sales-modal/sales-upgrade-modal/sales-upgrade-modal/sales-upgrade-modal.component';
import {Base64UrlService} from '@services/base64-url.service';
import {ButtonClickedTracking} from 'src/app/store/actions/rain-analytics-tracker.actions';
import {RainAnalyticsTrackerClick} from 'src/app/store/interfaces/rain-analytics-tracker';
import {ResetOrders} from 'src/app/store/actions/order.actions';
import {ServicesState} from 'src/app/store/state/services.state';
import {CartService} from '@services/cart.service';
import {ChangeWifiSpeed, EmptyCart, SetXtenderSpeed, StartRainOnePurchase} from 'src/app/store/actions/cart.action';
import {ServiceStatuses} from '@models/serviceDetail';
import {OrderState} from 'src/app/store/state/order.state';
import {CartErrorModalComponent} from '@pages/cart-page/cart-error-modal/cart-error-modal.component';
import {AuthenticationService} from '@services/auth.service';
import Swiper from 'swiper';
import {animate, style, transition, trigger} from '@angular/animations';
import {DataLayerService} from '@services/data-layer.service';
import {ViewportScroller} from "@angular/common";
import {ClickToClaimState} from "../../store/state/click-to-claim.state";
import { FetchClickToClaim } from 'src/app/store/actions/click-to-claim.actions';

//TODO we must remove jquery
declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0, height: 0}),
                animate('80ms ease-in', style({height: '*'})),
                animate('90ms ease-in', style({opacity: 1}))
            ]),
            transition(':leave', [
                animate('20ms ease-out', style({opacity: 0, height: 0}))
            ])
        ])
    ]
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit {
    @ViewChild('swiper') swiperRef: ElementRef;
    @ViewChild('bullet1') bullet1: ElementRef;
    @ViewChild('bullet2') bullet2: ElementRef;
    @ViewChild('bullet3') bullet3: ElementRef;
    @Select(FirebaseConfigsState.getFlags) flags$: Observable<RainFlags>;
    @Select(UIState.GetUIMode) selectedMode$: Observable<UIMode>;
    @Select(OrderState.GetallNonCancelledOrders) orders$: Observable<any>;
    @Select(ProductState.CatelogueIsLoaded) catalogueIsLoaded$: Observable<boolean>;
    @Select(CoreState.activeBreakpoint) ap$: Observable<string>;
    @Select(ImageViewModel.getRainOneBundleBG) image$: Observable<string>;

    uiMode = this.store.selectSnapshot(UIState.GetUIMode);
    isPlanSpecs: boolean = true;
    isFeatureSpecs: boolean = true;
    isXtender: boolean = true;
    isXtenderWrapper: boolean;
    swiper: Swiper;


    public scrollToShop: () => void;
    public latitude: number = DEFAULT_LATITUDE;
    public longitude: number = DEFAULT_LONGITUDE;
    messages: string[];

    public levels = RAIN_ONE_LEVELS;
    private readonly ngDestroy$ = new Subject();
    @Select(FirebaseConfigsState.getFlags) promoFlags$: Observable<RainFlags>;
    @Select(ClickToClaimState.getClickToClaimValues) clickClaim$: Observable<any>;
    clickToClaimAmount: any = 0;
    promoImageType$: Observable<string>;
    imagePath = '/assets/images/click-to-claim-promo/product-page/';
    imagePathTile = '/assets/images/click-to-claim-promo/';

    homeMobile: string;
    homeTablet: string;
    homeDesktop: string;

    workMobile: string;
    workTablet: string;
    workDesktop: string;

    workMobileTile: string;
    workTabletTile: string;
    workDesktopTile: string;

    homeMobileTile: string;
    homeTabletTile: string;
    homeDesktopTile: string;

    constructor(
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private modalService: NgbModal,
        private elementService: ElementService,
        private router: Router,
        private store: Store,
        private base64UrlService: Base64UrlService,
        private cartSVC: CartService,
        private modalSVC: NgbModal,
        private authSVC: AuthenticationService,
        private dataLayerService: DataLayerService,
        private viewportScroller: ViewportScroller
    ) {
        super();
        this.scrollToShop = this.elementService.scrollToShop;
    }

    ngOnInit() {
        this.store.dispatch(new FetchClickToClaim());
        this.promoImageType$ = this.promoFlags$.pipe(
            map((flags: RainFlags) => flags?.first_month_free ? 'free' : 'plain'),
            startWith('plain')
          );

        this.store.select(UIState.GetUIMode).pipe(takeUntil(this.ngDestroy$)).subscribe(uiMode => {
            this.uiMode = uiMode
        });
        const url = this.router.url.includes('work')

        if (url) {
            this.store.dispatch(new UIActions.SwitchUIMode({mode: 'sme'}));
        }

        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            if (params.hasOwnProperty('voucherNumber') && params.hasOwnProperty('emailAddress')) {
                const voucherModal = this.modalService.open(VoucherActivateModalComponent, {
                    centered: true,
                    size: <any>'sm',
                    windowClass: 'slideInUp d-flex'
                });
                const details = {
                    voucherNumber: this.sanitizeCode(params.voucherNumber),
                    emailAddress: params.emailAddress
                };
                voucherModal.componentInstance.voucherDetails = details;
                return voucherModal;
            }

            if (params.hasOwnProperty('rating') && params.hasOwnProperty('campaignId') && params.hasOwnProperty('email')) {
                this.showCustomerRatingModal(params.email, params.rating, params.campaignId);
                return;
            }

            if (params.hasOwnProperty('freeUpgrade')) {
                const {n, l, e} = params;

                if (n && l && e) {
                    try {
                        const name = this.base64UrlService.decodeBase64Url(n);
                        const lastName = this.base64UrlService.decodeBase64Url(l);
                        const email = this.base64UrlService.decodeBase64Url(e);

                        this.upgradeSaleModal(name, lastName, email);
                    } catch (error) {
                        console.error('Error decoding Base64 URL:', error);
                    } finally {
                        setTimeout(() => {
                            this.removeQueryParams();
                        }, 0);

                    }
                }
            }


            const mode = this.store.selectSnapshot(UIState.GetUIMode);
            switch (mode) {
                case 'sme':
                    this.store.dispatch(new UIActions.SwitchUIMode({mode: 'sme'}));
                    break;
                case 'consumer':
                    this.store.dispatch(new UIActions.SwitchUIMode({mode: 'consumer'}));
                    break;
                case 'mobile':
                    this.store.dispatch(new UIActions.SwitchUIMode({mode: 'mobile'}));
                default:
                    this.store.dispatch(new UIActions.SwitchUIMode({mode: 'consumer'}));
                    break;
            }

            if (!window.location.href?.toLocaleLowerCase()?.includes('/work')) {
                this.store.dispatch(new UIActions.SwitchUIMode({mode: 'consumer'}));
            }
        });

        // this.store.dispatch(new FetchClickToClaim());

        // this.clickClaim$.subscribe(clickClaimData => {
        //     if (clickClaimData) {
        //         this.clickToClaimAmount = clickClaimData;
        //         this.getHomeImagePath(this.clickToClaimAmount['consumer']);
        //         this.getWorkImagePath(this.clickToClaimAmount['sme']);
        //     }
        // });
    }

    getHomeImagePath(value: number) {
        this.homeDesktop = `${this.imagePath}Home_Desktop_${value}.webp`;
        this.homeMobile = `${this.imagePath}Home_Mobile_${value}.webp`;
        this.homeTablet = `${this.imagePath}Home_Tablet_${value}.webp`;

        this.homeDesktopTile = `${this.imagePathTile}Home_Desktop_${value}.webp`;
        this.homeMobileTile = `${this.imagePathTile}Home_Mobile_${value}.webp`;
        this.homeTabletTile = `${this.imagePathTile}Home_Tablet_${value}.webp`;
    }

    getWorkImagePath(value: number) {
        this.workDesktop = `${this.imagePath}Work_Desktop_${value}.webp`;
        this.workMobile = `${this.imagePath}Work_Mobile_${value}.webp`;
        this.workTablet = `${this.imagePath}Work_Tablet_${value}.webp`;

        this.workDesktopTile = `${this.imagePathTile}Work_Desktop_${value}.webp`;
        this.workMobileTile = `${this.imagePathTile}Work_Mobile_${value}.webp`;
        this.workTabletTile = `${this.imagePathTile}Work_Tablet_${value}.webp`;
    }

    goToPromoTeams() {
        this.router.navigateByUrl('/legal?section=promo');
    }

    ngAfterViewInit(): void {
        this.swiper = this.swiperRef?.nativeElement.swiper;
        this.bullet1.nativeElement.style.backgroundColor = '#0077C8';

        this.swiper.on('slideChange', () => {
            setTimeout(x => this.swiper.updateAutoHeight(), 60)
            if (this.swiper.activeIndex !== 0) {
                this.isXtenderWrapper = true
            } else {
                this.isXtenderWrapper = false
            }


            if (this.swiper.activeIndex === 0) {
                this.bullet1.nativeElement.style.backgroundColor = '#0077C8';
                this.bullet2.nativeElement.style.backgroundColor = '#c2c9cd';
                this.bullet3.nativeElement.style.backgroundColor = '#c2c9cd';
            }
            if (this.swiper.activeIndex === 1) {
                this.bullet1.nativeElement.style.backgroundColor = '#c2c9cd';
                this.bullet2.nativeElement.style.backgroundColor = '#0077C8';
                this.bullet3.nativeElement.style.backgroundColor = '#c2c9cd';
            }
            if (this.swiper.activeIndex === 2) {
                this.bullet1.nativeElement.style.backgroundColor = '#c2c9cd';
                this.bullet2.nativeElement.style.backgroundColor = '#c2c9cd';
                this.bullet3.nativeElement.style.backgroundColor = '#0077C8';
            }
            this.cdr.detectChanges()
        });

    }

    bulletNavigation(number: any) {
        this.swiper.slideTo(number)
    }

    togglePlanMenu(): void {
        this.isPlanSpecs = !this.isPlanSpecs;
        setTimeout(x => this.swiper.updateAutoHeight(), 10)
    }

    toggleFeatureMenu(): void {
        this.isFeatureSpecs = !this.isFeatureSpecs;
        setTimeout(x => this.swiper.updateAutoHeight(), 10)
    }

    toggleXtenderMenu(): void {
        this.isXtender = !this.isXtender;
        setTimeout(x => this.swiper.updateAutoHeight(), 10)
    }

    showCustomerRatingModal(email: string, rating: string, campaignId: string) {
        const modalRef = this.modalService.open(CustomerRatingModalComponent, {
            centered: true,
            size: <any>'md'
        });
        modalRef.componentInstance.email = email;
        modalRef.componentInstance.rating = rating;
        modalRef.componentInstance.campaignId = campaignId;
    }

    private sanitizeCode(code: string) {
        if (!code.includes("'")) return code;
        return code.replace("'", '');
    }

    public gotoTermsRainGo() {
        this.router.navigateByUrl('/legal?section=rainGo');
    }

    handleRequestSalesCallFromSticky() {
        const options = {size: 'sm' as any, windowClass: 'slideInUp', centered: true};
        const salesModalRef = this.modalService.open(SalesModalComponent, options);
        const productView = this.uiMode === 'sme' ? 'rainOne Work' : 'rainOne Home';
        let productHeadingFromRoute = this.uiMode == 'mobile' ? 'mobile' : 'one';

        salesModalRef.componentInstance.productName = productView;
        salesModalRef.componentInstance.subject = 'request a sales call';
        salesModalRef.componentInstance.isPromo = false;
        salesModalRef.componentInstance.showSignIn = false;
        salesModalRef.componentInstance.gmData = `From sticky - submit form`;
        salesModalRef.componentInstance.fromPage = 'homepage';
        salesModalRef.componentInstance.productHeading = productHeadingFromRoute;
    }

    handlePlayVideoTrigger() {
        // play video
        const options = {size: 'xl' as any, windowClass: 'videoModal', centered: true};
        const modalRef = this.modalService.open(RainOneModalVideoComponent, options);
    }

    gotoThe101() {
        this.router.navigateByUrl('/the101');
    }

    workNavigation() {
        this.store.dispatch([new UIActions.SwitchUIMode({mode: 'sme'})]);
        this.router.navigateByUrl('/work');
    }

    homeNavigation() {
        this.store.dispatch([new UIActions.SwitchUIMode({mode: 'consumer'})]);
        this.router.navigateByUrl('/home');
    }

    mobileNavigation() {
        this.store.dispatch([new UIActions.SwitchUIMode({mode: 'mobile'})]);
        this.router.navigateByUrl('/mobile');
    }

    private checkForWorkProducts() {
        let incompleteSMEOrders = [];
        this.orders$.subscribe(res => {
            incompleteSMEOrders = res.filter(order => !order.orderComplete && order.items.length >= 5);
        });

        const allServices = this.store.selectSnapshot(ServicesState.getAllServices);
        const activeWorkProducts = allServices?.filter(service => RAINONE_SME_IDS.includes(service.productId) && service.status === ServiceStatuses.Active);

        return activeWorkProducts.length >= 12 || (incompleteSMEOrders.length >= 2);
    }

    private showMaxRainOneModal() {
        let mode = this.store.selectSnapshot(UIState.GetUIMode)
        let message
        switch (mode) {
            case 'consumer':
                message = NO_MORE_RAIN_ONE_MESSAGE.replace(NUMBER_REPLACE, '0');
                break
            case 'sme':
                message = NO_MORE_RAIN_ONE_WORK_MESSAGE.replace(NUMBER_REPLACE, '0');
                break
            case 'mobile':
                message = NO_MORE_RAIN_MOBILE_MESSAGE.replace(NUMBER_REPLACE, '0');
        }

        CartErrorModalComponent.openModal(message, false, this.modalSVC);
    }

    private checkIfHasRainOne5G() {

        const isSignedIn = this.authSVC?.isSignedIn;
        if (!isSignedIn) return false;

        const allServices = this.store.selectSnapshot(ServicesState.getAllServices);

        const hasActiveRainONE = allServices?.filter(s => {
            return RAINONE_101_5G_SIM.includes(s.productId) && s.status === ServiceStatuses.Active;
        });

        if (hasActiveRainONE.length > 0) return true;

        const allOrders = this.store.selectSnapshot(OrderState.GetallNonCancelledOrders);

        let matchFound = false;

        let smeOrder = this.store.selectSnapshot(OrderState.GetAllSmeOrders)

        if ((allOrders && allOrders?.length > 0)) {

            const rainOneOrder = allOrders?.find(o => {
                o?.items?.forEach(item => {

                    if (RAINONE_101_5G_SIM.includes(item.productId) && (!RAINONE_SME_IDS.includes(item.productId))) {

                        matchFound = true;
                    }
                });

            })


            return matchFound;
        }

    }

    public addToCart(speed?: string) {

        this.store.dispatch([new ResetOrders(), new SetXtenderSpeed(speed)]);

        if (speed === '60' || speed === '100') {
            return this.store.dispatch(new Navigate(['xtenders-addon']));
        }

        if (this.uiMode === 'sme') {
            if (this.checkForWorkProducts()) {
                return this.showMaxRainOneModal();
            }
        } else {
            if (this.checkIfHasRainOne5G()) {
                return this.showMaxRainOneModal();
            }
        }

        const rainTrackingClickOj: RainAnalyticsTrackerClick = {
            buttonId: `get-started-${this.uiMode}`,
            buttonLabel: `get started ${this.uiMode}`,
            event: 'btn-click'
        }

        this.store.dispatch(new ButtonClickedTracking(rainTrackingClickOj));


        // const hasBundleInCart = this.store.selectSnapshot(CartState.GetSelectedRainOneBundle);
        // const isWorkBundle = hasBundleInCart?.config?.demographic === "SME"
        // let rainOneL1Product;
        //
        // if (this.uiMode !== 'sme') {
        //     //home mode
        //     if (hasBundleInCart) {
        //         //if cart contains work bundle clear cache and move forward
        //         if (isWorkBundle) {
        //             this.store.dispatch(new EmptyCart());
        //         } else {
        //             return this.store.dispatch(new Navigate(['/skin-selector']));
        //         }
        //     }
        //
        //     const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);
        //
        //     rainOneL1Product = isPostPaid
        //         ? this.store.selectSnapshot(ProductState.GetRain101aPPBase)
        //         : this.store.selectSnapshot(ProductState.GetRain101aAUBase);
        // }
        // else {
        //     //work mode
        //     if (hasBundleInCart) {
        //         //if cart contains home bundle then clear cache and move forward
        //         if (!isWorkBundle) {
        //             this.store.dispatch(new EmptyCart());
        //         } else {
        //             return this.store.dispatch(new Navigate(['/skin-selector']));
        //         }
        //     }
        //
        //     const isPostPaid = this.store.selectSnapshot(ServicesState.hasPostPaid);
        //
        //     rainOneL1Product = isPostPaid
        //         ? this.store.selectSnapshot(ProductState.GetRain101AUBaseWork)
        //         : this.store.selectSnapshot(ProductState.GetRain101AUBaseWork);
        //
        // }

        let selectedSpeed: string;
        if (this.uiMode === 'consumer') {
            if (!speed.includes('e')) {
                selectedSpeed = speed == '30' ? a30Id : speed == '60' ? pro60NoExtId : pro100NoExtId;
            } else {
                if (speed === '601e') {
                    selectedSpeed = pro601ExtId;
                }
                if (speed === '602e') {
                    selectedSpeed = pro602ExtId;
                }
                if (speed === '1001e') {
                    selectedSpeed = pro1001ExtId;
                }
                if (speed === '1002e') {
                    selectedSpeed = pro1002ExtId;
                }
            }

        } else if (this.uiMode === 'sme') {
            if (!speed.includes('e')) {
                selectedSpeed = speed == '30' ? work30Id : speed == '60' ? proWork60NoExtId : proWork100NoExtId;
            } else {
                if (speed === '601e') {
                    selectedSpeed = proWork601ExtId;
                }
                if (speed === '602e') {
                    selectedSpeed = proWork602ExtId;
                }
                if (speed === '1001e') {
                    selectedSpeed = proWork1001ExtId;
                }
                if (speed === '1002e') {
                    selectedSpeed = proWork1002ExtId;
                }
            }
        }

        this.store.dispatch(new EmptyCart());

        this.cartSVC
            .add(selectedSpeed, {
                [CONFIG_FRIENDLY_NAME]: '5G SIM',
                [CONFIG_COMMENTS]: undefined
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.store.dispatch([new StartRainOnePurchase(selectedSpeed),
                    new ChangeWifiSpeed(0),
                    new Navigate(['/skin-selector'])
                ]);

                this.dataLayerService.addToCart();
            });
        return;
    }

    public routeToNvidia() {
        this.store.dispatch(new Navigate(['/nvidia']));
    }

    gotoTerms() {
        this.router.navigateByUrl('/legal?section=terms');
    }

    private upgradeSaleModal(name, lastName, email) {
        const options = {
            size: 'md' as any,
            windowClass: 'slideInUp',
            centered: true,
            backdrop: 'static' as const,
            keyboard: false,
        };
        const salesModalRef = this.modalService.open(SalesUpgradeModalComponent, options);
        salesModalRef.componentInstance.subject = 'Monarch upgrade to rainOne 5G';
        salesModalRef.componentInstance.name = name;
        salesModalRef.componentInstance.lastName = lastName;
        salesModalRef.componentInstance.email = email;
        salesModalRef.componentInstance.gmData = `Monarch upgrade to rainOne 5G - submit form`;
        salesModalRef.componentInstance.fromPage = 'homepage';
    }

    removeQueryParams(): void {
        const navigationExtras: NavigationExtras = {
            replaceUrl: true,
            queryParams: {}
        };

        setTimeout(() => {
            this.router.navigate([], navigationExtras);
        }, 0);
    }

    gotoThe101Xtender() {
        this.router.navigateByUrl('/the101#xtender-section').then(() => {
            // Adding a slight delay ensures the DOM is updated
            setTimeout(() => {
                const yOffset = -100; // Change this to set your offset in pixels
                const elementPosition = this.viewportScroller.getScrollPosition();
                this.viewportScroller.scrollToPosition([elementPosition[0], elementPosition[1] + yOffset]);
            }, 100);
        });
    }

    gotoTermsPromo() {
        this.router.navigateByUrl('/legal?section=promo');
    }
}
