import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  private activityTimeout: any;
  private inactivityTime = 45000; 
  public userInactive$ = new Subject<boolean>();

   constructor( private ngZone: NgZone) {
    this.startActivityTracking();
  }

  public setInactivityTime(milliseconds: number): void {
    this.inactivityTime = milliseconds;
    this.resetTimer();
  }

  startActivityTracking() {
    const activityEvents = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
    activityEvents.forEach(event =>
      document.addEventListener(event, () => this.resetTimer(), true)
    );

    this.resetTimer();
  }

  private resetTimer() {
    clearTimeout(this.activityTimeout);

    this.ngZone.runOutsideAngular(() => {
      this.activityTimeout = setTimeout(() => {
        this.ngZone.run(() => {
          this.userInactive$.next(true); 
        });
      }, this.inactivityTime);
    });
  }
}
