<div class="modal-header border-0">
    <div class="modal-img-close-btn z-index-5">
        <img alt="Close" aria-label="Close" class="pointer" (click)="activeModal.dismiss('Cross click')"
             data-dismiss="modal" data-target="#thankYouModal" src="../../assets/svg/close.svg"/>
    </div>
</div>

<div class="modal-body">
    <div>
        <div class="text-center" *ngIf="!isSignedIn && showSignIn">
            <div class="text-primary h2 font-weight-semi-bold ">
                are you an existing customer?
            </div>

            <p>Please sign in to <a class="text-primary font-weight-semi-bold underline" (click)="goTo('/my-rain')">
                my rain</a> to get in touch</p>

            <button class="btn-primary text-white btn btn-sm" (click)="goTo('/my-rain')">
                sign in
                <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            </button>
        </div>
        <div class="header-wrapper">
        <ng-container *ngIf="(flags$ | async)?.first_month_free" >
          <img (click)="gotoTermsPromo()" src="../../../assets/images/sales-modal/free-promo-star.webp" alt="promo images - sales modal" class="promo-image">
           </ng-container>
                <div class="text-container">
                <div class="header-description text-primary mb-10">
                    you're one call away from getting
                    rain<b>{{ productHeading == '' || productHeading == undefined ? productHeadingFromRoute : productHeading }}</b>
                    <sup>&#8482;</sup>
                </div>
            </div>
        </div>
        <div class="header-wrapper text-description mb-10">
            <div>Please leave your details below and one of our agents will get in touch shortly.</div>
        </div>
    </div>

    <div class="d-flex justify-content-center">
        <form [formGroup]="contactForm" class="desk-60" (onSubmit)="recaptureBypass()">
            <!-- name -->
            <div class="form-group">
                <rain-rain-input-sales-modal maxlength="24" [showLabel]="false" placeholder="Name" label="Name"
                                             [control]="contactForm.get('name')" [type]="'text'"
                                             [messages]="nameValidationMessages">
                </rain-rain-input-sales-modal>
            </div>

            <!-- email -->
            <div class="form-group">
                <rain-rain-input-sales-modal label="email address" [showLabel]="false" placeholder="Email address"
                                             [control]="contactForm.get('email')" [type]="'email'"
                                             [messages]="emailValidationMessages">
                </rain-rain-input-sales-modal>
            </div>

            <!-- cellNumber -->
            <div class="form-group">
                <rain-rain-input-sales-modal label="contact number" [showLabel]="false" placeholder="Contact number"
                                             [control]="contactForm.get('number')" [type]="'tel'"
                                             [messages]="cellNumberValidationMessages"
                                             [maxlength]="13">
                </rain-rain-input-sales-modal>
            </div>

            <!-- Question -->
            <!-- <div class="form-group">
                <rain-rain-textarea maxlength="600" [showLabel]="false" placeholder="Product interest or questions"
                                    label="Product interest or questions" [control]="contactForm.get('questions')"
                                    [type]="'text'"
                                    [messages]="nameValidationMessages"></rain-rain-textarea>
            </div> -->
            <div class="d-flex justify-content-between align-items-center my-5 flex-responsive">

                <ng-container *ngIf="clickClaim$ | async as clickToClaimData">
                    <ng-container *ngIf="clickToClaimData[clickToClaimMode]; else noClickToClaim">
                        <div class="click-n-claim">
                            <h4>
                                CLICK & CLAIM your
                                <strong>{{ clickToClaimData[clickToClaimMode] | currency:'R':'symbol':'1.0-0' }}
                                    coupon!</strong>
                            </h4>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-template #noClickToClaim>
                    <label class="accept text-primary">
                        <input type="checkbox" class="custom-checkbox" [checked]="whatsAppOptIn"
                               (click)="whatsAppOptin()">
                        <span class="accept-text">
                            Opt in for WhatsApp communications.
                        </span>
                    </label>
                </ng-template>

                <button
                        [disabled]="contactForm.invalid || loading || sending"
                        [className]="(contactForm.invalid ? ' btn-tertiary' : ' btn-primary text-white') + ' btn btn-md'"
                        (click)="submit()">
                    request a sales call
                    <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>

            <div *ngIf="clickClaim$ | async as clickToClaimData">
                <div *ngIf="clickToClaimData[clickToClaimMode]">
                    <label class="accept text-primary">
                        <input type="checkbox" class="custom-checkbox" [checked]="whatsAppOptIn"
                               (click)="whatsAppOptin()">
                        <span class="accept-text">
                        Opt in for WhatsApp communications.
                    </span>
                    </label>
                </div>
            </div>
        </form>

    </div>
    <div class="text-disclaimer desk-60-top">
        By requesting a sales call, you are agreeing to our
        <a href="/legal?section=policies" target="_blank" class="text-primary text-decoration-underline">
            Privacy Policy</a> &
        <a href="/legal?section=terms" target="_blank" class="text-primary text-decoration-underline">
            Terms of Service</a>
        <hr>
        <div>If you need assistance with a <b>support</b> related query
            <a (click)="askRain()" class="text-primary text-decoration-underline askRain">
                please click here
            </a>
        </div>
    </div>
</div>