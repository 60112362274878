import { Component, OnInit, HostListener, AfterViewInit, Renderer2 } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { UIMode, UIState } from 'src/app/shared/store/state/ui.state';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CarouselConfig } from './carousel-config.interface';
import { FirebaseConfigsState } from 'src/app/core/store/state/firebase-configs.state';
import { RainFlags } from 'src/app/core/interfaces/rain-flags.interface';
@Component({
  selector: 'rain-carousel-one',
  templateUrl: './carousel-one.component.html',
  styleUrls: ['./carousel-one.component.scss']
})
export class CarouselOneComponent implements AfterViewInit, OnInit{
  @Select(UIState.GetUIMode) selectedMode$: Observable<UIMode>;
  @Select(FirebaseConfigsState.getFlags) promoFlags$: Observable<RainFlags>;
  promoImageType$: Observable<string>;
  public productMode: string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  carouselConfig: CarouselConfig;
  slides: { img: string, text: SafeHtml, textClass: string }[];

  constructor(private sanitizer: DomSanitizer,private router: Router,private renderer: Renderer2) {}
  
  ngOnInit(): void {
    this.promoImageType$ = this.promoFlags$.pipe(
      map((flags: RainFlags) => flags?.first_month_free ? 'free' : 'plain'),
      startWith('plain')
    );
    this.updateImages(window.innerWidth);
    this.selectedMode$.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      const mode = result;
      this.productMode = mode;
      this.updateImages(window.innerWidth);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateImages((event.target as Window).innerWidth);
  }

  private updateImages(windowWidth: number): void {
    const basePaths = {
      consumer: '/assets/images/rain-101/carousel/consumer',
      sme: '/assets/images/rain-101/carousel/sme',
      mobile: '/assets/images/rain-101/carousel/mobile',
    };

    let basePath = basePaths[this.productMode] || basePaths.consumer;
    let prefix = windowWidth <= 600 ? 'mobile' : (windowWidth <= 1024 ? 'tablet' : 'desktop');
    let textPrefix = prefix === 'tablet' ? 'desktop' : prefix;

    const slidesData = {
      consumer: {
        desktop: [
          { text: '<div class="top-div"><strong>the101<span class="trademark101">™</span></strong> <br> with <strong>touch<br> screen</strong> display</div> <div class="bottom-div"><strong> rainone</strong>home<span class="trademark">™</span></div> ', class: 'text-top-left' },
          { text: '<div class="top-div">powerful <br> <strong> AX3600 wifi</strong></div> <div class="bottom-div"><strong> rainone</strong>home<span class="trademark">™</span></div>', class: 'text-centered' },
          { text: '<div class="top-div"><strong>scan to <br> connect</strong></div> <div class="bottom-div"><strong>  rainone</strong>home<span class="trademark">™</span></div>', class: 'text-centered' },
          { text: '<div class="top-div">built-in <br> <strong> speed test</strong></div> <div class="bottom-div"><strong> rainone</strong>home<span class="trademark">™</span></div>', class: 'text-centered' },
          { text: '<div class="top-div">and<strong> direct<br> messaging</strong></div> <div class="bottom-div"><strong> rainone</strong>home<span class="trademark">™</span></div>', class: 'text-centered' }
        ],
        mobile: [
          { text: '<strong>the101<span class="trademark">™</span></strong> <br> with <strong>touch<br> screen</strong> display', class: 'text-top-left' },
          { text: 'powerful <strong> AX3600 wifi</strong>', class: 'text-centered' },
          { text: '<strong>scan to connect</strong>', class: 'text-centered' },
          { text: 'built-in <strong> speed test</strong>', class: 'text-centered' },
          { text: 'and<strong> direct  messaging</strong>', class: 'text-centered' }
        ]
      },
      sme: {
        desktop: [
          { text: '<div class="top-div"><strong>rainone </strong>work<span class="trademark">™</span> <br> is here </div>', class: 'text-centered' },
          { text: '<div class="top-div"><strong>just plug in</strong> <br> & begin</div> <div class="center-div sub-tittle"> <strong> Unlimited </strong> 5G wifi that  <br> works <strong> out the box.</strong></div><div class="bottom-div"><strong> rainone</strong>work<span class="trademark">™</span></di>', class: 'text-centered' },
          { text: '<div class="top-div">keep your team <br> <strong> connected</strong></div> <div class="center-div sub-tittle"><strong>FREE</strong> monthly calls & data<br> for <strong> 5 phones.</strong></div><div class="bottom-div"><strong> rainone</strong>work<span class="trademark">™</span></div>', class: 'text-centered' },
          { text: '<div class="top-div"><strong>assign </strong> SIMs to<br>your team </div><div class="center-div sub-tittle">Let them manage their own <br>data & minutes.</div><div class="bottom-div"><strong> rainone</strong>work<span class="trademark">™</span></div>', class: 'text-centered' } 
        ],
        mobile: [
          { text: '<strong>rainone </strong>work<span class="trademark">™</span> <br> is here', class: 'text-top-left' },
          { text: '<strong>Unlimited </strong> 5G wifi that<br> works <strong> out the box.</strong></div>', class: 'text-centered' },
          { text: '<strong>FREE </strong> calls & data<br>for 5 phones', class: 'text-centered' },
          { text: '<strong>assign </strong> SIMs to<br>your team', class: 'text-centered' }
        ]
      },
      mobile: {
        desktop: [
          { text: '<div class="top-div"><strong> rain</strong>mobile<span class="trademark">™</span></div> <div class="bottom-div">top ups now<br> <strong>R10 a gig!</strong></div><p class="terms">Limited offer. *Ts & Cs apply</p> ', class: 'text-centered' }
        ],
        mobile: [
          { text: '<div class="top-div-mobi">top ups now<br> <strong>R10 a gig!</strong></div><p class="terms">Limited offer. *Ts & Cs apply.</p>', class: 'text-centered' }
        ]
      }
    };
  
    const slideText = slidesData[this.productMode]?.[textPrefix] || [];

    this.slides = slideText.map((slide, i) => ({
        img: `${basePath}-${prefix}/hero-plain.webp`,
        text: this.sanitizer.bypassSecurityTrustHtml(slide.text),
        textClass: slide.class
    }));
  
    this.initializeSlickConfig();
  }

  private initializeSlickConfig(): void {
    this.carouselConfig = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      dots: this.slides.length > 1,
      arrows: false,
      cssEase: 'ease-out',
      useTransform: false,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: false,
      centerMode: false
    };
  }

  private termsListener: () => void;
  ngAfterViewInit() {
    this.termsListener = this.renderer.listen('document', 'click', (event: Event) => {
      const target = event.target as HTMLElement;
      if (target && target.id === 'terms') {
        this.gotoTerms();
      }
    });
  }

  gotoTerms() {
    this.router.navigateByUrl('/legal?section=rainGo');
  }

  goToPromoTeams() {
    this.router.navigateByUrl('/legal?section=promo');
}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.termsListener) {
        this.termsListener();
      }
  }
}
