import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {includes, isNil} from 'lodash';
import {Observable} from 'rxjs';
import {ConfigService} from '@services/config.service';
import {IdleService} from '@services/idle.service';
import {AuthenticationService} from '@services/auth.service';
import {HTTP_HEADER_API_KEY, HTTP_HEADER_KEY} from '@models/constants';
import {Store} from '@ngxs/store';

@Injectable({
    providedIn: 'root'
})
export class AddHeaderInterceptor implements HttpInterceptor {

    constructor(
        private configService: ConfigService,
        private idleTimeoutService: IdleService,
        private authenticationService: AuthenticationService,
        private store: Store
    ) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        if (includes(request.url, 'config')) {
            return next.handle(request);
        }

        if (request.url == this.configService.RAIN_NETWORK_CHECK_API) {
            return next.handle(request);
        }

        if (this.authenticationService.isSignedIn) {
            this.idleTimeoutService.resetTimer();
        }

        let apiKey = request.headers?.get(HTTP_HEADER_API_KEY);

        let key = request.headers?.get(HTTP_HEADER_KEY);

        if (isNil(apiKey)) {
            apiKey = this.configService.API_KEY;
        }

        if (isNil(key)) {
            key = this.configService.KEY;
        }

        if (request.url.includes('sit-bss-api.rain.network')) {
            apiKey = '6a5xmlS33EQA7dpOJ89lvgAPqMkmVsGn'
        }

        let newRequest: HttpRequest<any>;

        if (request.url.includes('/axiom/rain-go/')) {
            newRequest = request.clone({
                setHeaders: {
                    key
                }
            });
        } else {
            newRequest = request.clone({
                setHeaders: {
                    apiKey,
                    key
                }
            });
        }
        return next.handle(newRequest);
    }
}
