  <ng-container *ngIf="(activeBreakpoint$ | async) as activeBreakpoint">

  <div class="container-fluid bg-light-grey sat20" *ngIf="page != 'home'; else homePage">
    <div class="container">
      <div class="sat20-grid">
        <div class="sat20-grid__copy text-primary text-align-center-mobi">
          <div class="heading">collect your <svg  class="inline-svg" width="96" height="22" viewBox="0 0 82 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M79.9435 12.7766C79.9435 8.84207 77.0562 5.72247 73.1225 5.72247C70.5064 5.72247 68.3756 7.19494 67.2398 9.242C66.519 7.21786 64.2348 5.73591 61.5207 5.72168C61.5207 5.72168 61.5207 5.72168 61.5199 5.72168C61.5199 5.72168 61.5199 5.72168 61.5191 5.72168C58.8034 5.73591 56.5184 7.22023 55.7991 9.24595C54.7424 7.32693 52.7364 5.81257 49.7299 5.81257C45.0271 5.81257 42.769 9.51389 42.769 12.6801C42.769 15.8464 45.0271 19.5477 49.7299 19.5477C52.5238 19.5477 54.4555 18.2404 55.5628 16.5158V19.1509H59.4396V13.0698C59.4396 12.2778 59.3076 9.80238 61.5191 9.80238C61.5191 9.80238 61.5191 9.80238 61.5199 9.80238C61.5207 9.80238 61.5199 9.80238 61.5207 9.80238C63.7329 9.80238 63.6001 12.2778 63.6001 13.0698V19.1509H67.4769V16.4225C68.7146 18.2918 70.84 19.5042 73.1913 19.5042C74.5184 19.5042 75.7988 19.1082 76.8697 18.34C77.5692 17.8634 78.192 17.2343 78.7152 16.466L75.5798 14.9928C75.0463 15.6598 74.3666 16.2447 73.1913 16.2447C71.6777 16.2447 70.3973 15.3366 70.1642 13.8001H79.8494C79.896 13.4974 79.9427 13.2879 79.9427 12.7758L79.9435 12.7766ZM49.7291 15.9404C48.0527 15.9404 46.6324 14.5667 46.6324 12.6809C46.6324 10.7951 48.0527 9.42142 49.7291 9.42142C51.4055 9.42142 52.8258 10.7951 52.8258 12.6809C52.8258 14.5667 51.4055 15.9404 49.7291 15.9404ZM70.2124 11.1942C70.3752 10.426 71.1435 8.98276 73.1455 8.98276C75.1475 8.98276 75.9157 10.426 76.0793 11.1942H70.2124Z" fill="#0077C8"/>
            <path d="M28.9082 19.1556H32.7811V12.1094C32.7811 11.3159 32.7811 9.40316 34.9981 9.40316C37.0277 9.40316 37.0277 11.176 37.0277 12.0865V19.1556H40.9014V11.0598C40.9014 8.51636 40.1078 7.48966 39.4084 6.88344C38.7081 6.27644 37.332 5.76349 36.0951 5.76349C33.7856 5.76349 32.9684 6.953 32.5724 7.58293H32.5258V6.20689H28.9098V19.1556H28.9082ZM27.2745 19.1556H23.4017V6.20689H27.2745V19.1556ZM11.2687 12.7156C11.2687 11.2463 12.2487 9.37944 14.465 9.37944C16.6582 9.37944 17.7316 11.1056 17.7316 12.669C17.7316 13.9288 16.9151 15.9822 14.4887 15.9822C12.1555 15.9822 11.2695 13.9288 11.2695 12.7156M21.6052 6.20609H17.7324V7.58214H17.6857C17.2194 6.7886 16.0291 5.7627 13.9062 5.7627C10.453 5.7627 7.39664 8.4223 7.39664 12.669C7.39664 16.379 9.80017 19.5982 13.9062 19.5982C15.4221 19.5982 17.0321 19.0149 17.6857 17.755H17.7324V19.1548H21.6052V6.20609ZM0.115723 6.20689H3.75539V7.58372H3.80203C4.12845 6.95379 4.80501 5.76428 7.04493 5.76428V9.66082C5.24841 9.70745 3.98855 10.0805 3.98855 12.0406V19.1564H0.115723V6.20689Z" fill="#0077C8"/>
            <path d="M27.6467 2.95175C27.6467 1.67687 26.6128 0.643066 25.338 0.643066C24.0631 0.643066 23.0293 1.67687 23.0293 2.95175C23.0293 4.22662 24.0631 5.26043 25.338 5.26043C26.6128 5.26043 27.6467 4.22662 27.6467 2.95175Z" fill="#0077C8"/>
            <path d="M78.5714 4.08472V4.2974H77.9995V6.11095H77.738V4.2974H77.166V4.08472H78.5714Z" fill="#0077C8"/>
            <path d="M80.8605 4.11921V6.11095H80.599V4.52158L79.9638 6.11095H79.731L79.093 4.52733V6.11095H78.8314V4.11921H79.1447L79.8517 5.79192L80.5473 4.11921H80.8605Z" fill="#0077C8"/>
          </svg> instore & score a free SA20 bat and ball</div>
          <div class="legal pt-5"><a href="/legal?section=competition" class="text-decoration-underline"
              target="_blank">While stocks last. Ts & Cs apply.</a></div>
        </div>
        <div class="sat20-grid__image">
          <img src="./assets/images/t20/batballLockUp.webp" class="mw100" alt="" *ngIf="activeBreakpoint !== 'mobile'">
          <img src="./assets/images/t20/batballLockUp.webp" class="mw100" alt=""
            *ngIf="activeBreakpoint === 'mobile'">
        </div>
       
      </div>
    </div>
  </div>
  <ng-template #homePage>
    <div class="container sat20 sat-20-home-page bg-light-blue">
      <div class="sat20-grid">
        <div class="sat20-grid__copy text-primary">
          <div class="heading">collect instore & score a FREE <strong>SA20 bat & ball</strong></div>
          <div class="legal pt-5" *ngIf="activeBreakpoint !== 'mobile'"><a href="/legal?section=competition"
              class="text-decoration-underline" target="_blank">While stocks last. Ts & Cs apply.</a></div>
        </div>
        <div class="sat20-grid__image">
          <img src="./assets/images/t20/sat20-bat-logo.png" class="mw100" alt="" *ngIf="activeBreakpoint !== 'mobile'">
          <img src="./assets/images/t20/mobi-sat20-bat-logo.png" class="mw100" alt=""
            *ngIf="activeBreakpoint === 'mobile'">
        </div>
        <div *ngIf="activeBreakpoint === 'mobile'" class="legal pt-5 pb-10 pl-10">
          <a href="/legal?section=competition" class="text-decoration-underline" target="_blank">While stocks last. Ts &
            Cs apply.</a>
        </div>
      </div>
    </div>
  </ng-template>

</ng-container>