<section class="carousel-block" *ngIf="(promoImageType$ | async) != 'free'">
    <div class="slider-wrapper">
    <ngx-slick-carousel class="carousel" [config]="carouselConfig">
        <div ngxSlickItem *ngFor="let slide of slides" class="slide">
          <div class="slider-image">
            <img src="/assets/images/rain-101/carousel/mobile-desktop/hero-plain.webp" alt="Slide image">
          </div>
          <div class="slider-copy" [ngClass]="slide.textClass" (click)="gotoTerms()" [innerHTML]="slide.text"></div>
        </div>
      </ngx-slick-carousel>
    </div>
</section>

<section class="home-landing-page-container" *ngIf="(promoImageType$ | async) === 'free'">
  <div class="blocks-container">
    <div class="block-item consumer">
      <span class="overlay-span" (click)="goToPromoTeams()"></span>
      <div class="img-container">
          <!-- <picture>
              <source media="(max-width:480px)"
              src="/assets/images/rain-101/carousel/hero/hero-mobile-free.webp">
              <source media="(max-width:768px)"
              src="/assets/images/rain-101/carousel/hero/hero-tablet-free.webp">
              <source media="(max-width:1024px)"
              src="/assets/images/rain-101/carousel/hero/hero-desktop-free.webp">
              <img class="home-page-image"
              src="/assets/images/rain-101/carousel/hero/hero-desktop-free.webp"
                   alt="rainone home unlimited 5g wifi">
          </picture> -->

          <picture>
            <source media="(max-width:600px)"
            [attr.srcset]="'/assets/images/rain-101/carousel/hero/hero-mobile-' + (promoImageType$ | async) + '.webp'">
            <source media="(max-width:991px)"
            [attr.srcset]="'/assets/images/rain-101/carousel/hero/hero-tablet-' + (promoImageType$ | async) + '.webp'">
            <img class="home-page-image br-8"
            [attr.srcset]="'/assets/images/rain-101/carousel/hero/hero-desktop-' + (promoImageType$ | async) + '.webp'"
                 alt="rainone home unlimited 5g wifi">
        </picture>
      </div>
      <div class="text-content">
          <div class="hide-for-mobile-screen">
              <div class="title">
                  <img src="/assets/images/home-page/3-door-landing-page/rain_mobile.svg" alt=""/>
              </div>
              <div class="content-text home-content">
                  <p>HD voice calls, <strong>2 gigs, 60 mins</strong></p>
                  <p>& <strong>100 sms</strong> every month</p>
              </div>
              <div class="content-price">
                  <!-- <p class="from-size">from</p> -->
                  <p><strong>R165</strong></p>
                  <p class="from-size">month-to-month</p>
              </div>
          </div>
  </div>

</div>


  </div>
</section>

  