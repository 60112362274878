import { ModelHelper } from '@models/modelHelper';
import { IRemoteCoverage } from '@models/remote/remoteCoverage';
import { IRemoteCoverageResult } from '@models/remote/remoteCoverageResult';
import { IDictionary } from '@models/dictionary';
import { isNil } from 'lodash';

/**
 * CoverageTypes @param
 */
export enum CoverageTypes {
  FourG,
  FiveG
}

/**
 * ICoverageResultDetail @param
 */
export interface ICoverageResultDetail {
  /**
   * coverage @param {ICoverageDetail[]}
   */
  coverage: ICoverageDetail[];
}

/**
 * ICoverageDetail @param
 */
export interface ICoverageDetail {
  /**
   * name @param {string}
   */
  name: string;

  /**
   * technicalName @param {string}
   */
  technicalName: string;

  /**
   * latitude @param {number}
   */
  latitude: number;

  /**
   * longitude @param {number}
   */
  longitude: number;

  /**
   * distance @param {number}
   */
  distance: number;

  /**
   * bearing @param {number}
   */
  bearing: number;

  /**
   * signal @param {number}
   */
  signal: number;

  /**
   * type @param {CoverageTypes}
   */
  type: CoverageTypes;
  siteId?: string
}

export class CoverageResultDetail {
  static adapt(remote: IRemoteCoverageResult): ICoverageDetail[] {
    const coverages = [];

    for (const key in remote.coverage) {
      if (remote.type == '4G') {
        const coverage = remote.coverage[key] as IRemoteCoverage;

        coverages.push(CoverageDetail.adapt(coverage, CoverageTypes.FourG, key));
      } else {
        const coverage = remote.coverage[key][key] as IRemoteCoverage;

        coverage.signal = remote.coverage[key].signal as IDictionary<number>;

        coverages.push(CoverageDetail.adapt(coverage, CoverageTypes.FiveG, key));
      }
    }

    return coverages;
  }
}

export class NearestCoverageResultDetail {
  static adapt(remote: IRemoteCoverageResult, type: CoverageTypes): ICoverageDetail[] {
    /**
     * coverages @param {[]}
     */
    const coverages = [];

    for (const key in remote.coverage) {
      const coverage = remote.coverage[key] as IRemoteCoverage;

      coverages.push(CoverageDetail.adapt(coverage, type));
    }

    return coverages;
  }
}

/**
 * CoverageDetail @param
 */
export class CoverageDetail {
  static adapt(remote: IRemoteCoverage, type: CoverageTypes, id?: string): ICoverageDetail {
    let x: ICoverageDetail = {
      /**
       * name @param {remote.name}
       */
      name: remote.name,

      /**
       * technicalName @param {remote.techname}
       */
      technicalName: remote.techname,

      /**
       * latitude @param {ModelHelper.toFloat(remote.latitude)}
       */
      latitude: ModelHelper.toFloat(remote.latitude),

      /**
       *  longitude @param {ModelHelper.toFloat(remote.longitude)}
       */
      longitude: ModelHelper.toFloat(remote.longitude),

      /**
       * distance @param {remote.distance}
       */
      distance: remote.distance,

      /**
       * bearing @param {remote.bearing.degrees,type}
       */
      bearing: remote.bearing.degrees,
      type,

      /**
       * signal @param {null}
       */
      signal: null,
      siteId: id
    };

    if (isNil(remote.signal) == false) {
      var signalKey = Object.keys(remote.signal)[0];
      x.signal = remote.signal[signalKey];
    }

    return x;
  }
}

export class UserAddress {
  streetNumber?: string;
  streetName?: string;
  city?: string;
  suburb?: string;
  province?: string;
  postalCode?: string;
  latitude?: number | null;
  longitude?:  number | null;
}
