import { BillingStoreService } from 'src/app/store/billing-store.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { CACHE_AUTH_TOKEN, CACHE_CART, CACHE_Credential_TOKEN, CACHE_GENERATE_VOUCHER, CACHE_IDM_TOKEN, CACHE_SME_TOKEN, CACHE_SUPPORT_ADDED, CACHE_SUPPORT_SALE_UPGRADE_ADDED } from '@models/constants';
import { Result, Statuses } from '@models/result';
import { CacheService } from './cache.service';
import { ConfigService } from './config.service';
import { IdleService } from './idle.service';
import { ServiceHelper } from './serviceHelper';
import { TokenService } from './token.service';
import { Store } from '@ngxs/store';
import { AssignHasPrepaidFlag, AssignTokens, ClearProfile, ClearTokens, SetTokens, UserRole } from '../core/store/actions/auth.actions';
import { ResetOrders } from '../store/actions/order.actions';
import { ClearServicePolicies } from '../store/actions/services.actions';
import { ResetUserBillingAccountStatus } from '../core/store/actions/billing.actions';
import { CoverageCheckedOnRegistration, ResetCart } from '../store/actions/cart.action';
import { AxiomUser } from '../shared/interfaces/axiom/axiom.user.interfaces';
import { AxiomDigitalIdentityUserDetail } from '../shared/interfaces/axiom/axiom.interfaces';
import { UIActions } from '../shared/store/actions/ui.actions';
import { ClearState } from '../store/actions/number-porting-actions';
import { ClearServicesState } from '../v2/store/actions/services.action';
import { AuthState } from '../core/store/state/auth.state';
import { ClearBillingState } from '../v2/store/actions/billing.action';
import { ClearCoverageState } from '../core/store/actions/coverage.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public onSignedInStatusChanged: Subject<boolean> = new Subject<boolean>();

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private tokenService: TokenService,
    private cacheService: CacheService,
    private idleTimeoutService: IdleService,
    private billingStore: BillingStoreService,
    private store: Store
  ) { }
  
  // token - TAUSPACE token, idm - identity token manger
  signIn(token: string, idmToken: string, tokenSme?: string, hasPrepaid?: boolean) {
    this.store.dispatch(new AssignTokens({ idm: idmToken, auth: token }));
    if ( hasPrepaid ) this.store.dispatch(new AssignHasPrepaidFlag(hasPrepaid));

    this.cacheService.clear([CACHE_CART]);

    this.tokenService.set(CACHE_AUTH_TOKEN, token);
    
    this.tokenService.set(CACHE_IDM_TOKEN, idmToken);
    
    if (tokenSme) this.tokenService.set(CACHE_SME_TOKEN, tokenSme);

    this.idleTimeoutService.startTimer();

    this.onSignedInStatusChanged.next(true);
  }

  public idmLogin(userName: string, password: string): Observable<Result<string>> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    const requestPath = `${this.configService.AXIOM_IDM_URL}/authenticate`;

    const body = {
      auth: {
        email: userName,
        password
      }
    };

    return this.http.post(requestPath, body, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result: any) => {
        return Result.success(result.token);
      }),
      catchError(result => ServiceHelper.handleError<string>(result))
    );
  }

  public axiomLogin(userName: string, password: string): Observable<Result< string | {token?: string, hasPrepaid?: boolean}>> {
    const requestPath = `${this.configService.AXIOM_URL}/digital-identity/authenticate`;
    
    const body = {
      auth: {
        email: userName,
        password
      }
    };

    return this.http.post<AxiomUser>(requestPath, body, this.httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map((result) => {
        this.signIn(result.token, result.tokenCredential, "", result?.hasPrepaid);
        this.tokenService.set(CACHE_Credential_TOKEN, result.tokenCredential);
        //dispatch the user role (Consumer or SME)
        if (result.tokenSme) {
          this.store.dispatch(new UserRole('sme'));
          this.tokenService.set(CACHE_SME_TOKEN, result.tokenSme);
        }
        else {
          this.store.dispatch(new UserRole("consumer"));
        }

        this.store.dispatch(new SetTokens(result?.token, result?.tokenCredential, result?.tokenSme));

        const response = {
          token: result?.tokenCredential, 
          hasPrepaid: result?.hasPrepaid
        }

        return Result.success(response);
      }),
      catchError((result) => {
        return ServiceHelper.handleError<string>(result)
      })
    );
  }

  fetchAxiomUserById() { 
   
    const token = this.store.selectSnapshot(AuthState.getTokenCredential);
    const userId = this.store.selectSnapshot(AuthState.getUserIdFromTokenCredential);

    if (token) {
      const httpAuthHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        })
      }
      const url = `${this.configService.AXIOM_URL}/digital-identity/digitalIdentity/${userId}`;
      return this.http.get<AxiomDigitalIdentityUserDetail>(url, httpAuthHeader);
    }

    
}

  // public login(userName: string, password: string): Observable<Result<boolean>> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   };

  //   const requestPath = `${this.configService.API_URL}/auth/token`;
  //   // const requestPath = `https://sit-bss-api.rain.co.za/V1/fullcircle/auth/token`;

  //   const body = {
  //     auth: {
  //       email: userName,
  //       password
  //     }
  //   };


  //   return forkJoin(this.http.post(requestPath, body, httpOptions), this.idmLogin(userName, password))
  //     .pipe(
  //       timeout(this.configService.API_TIMEOUT),
  //       map((results) => {

  //         const response: any = results[0];

  //         const idmResult: Result<string> = results[1];

  //         if (idmResult.status != Statuses.Success) {
  //           throw new Error(idmResult.message);
  //         }

  //         this.signIn(response.token, idmResult.value);

  //         return Result.success(true);

  //       }),
  //       catchError(result => ServiceHelper.handleError<boolean>(result))
  //     );
  // }

  public passwordReset(username: string): Observable<Result<boolean>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const requestPath = `${this.configService.AXIOM_URL}/digital-identity/authenticate/password_reset/${username}`;

    return this.http.get(requestPath, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map(_ => {
        return Result.success(true);
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );
  }

  public resetPassword(password: string, passwordResetToken: string): Observable<Result<boolean>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const requestPath = `${this.configService.AXIOM_URL}/digital-identity/authenticate/password_reset`;

    const body = {
      password,
      password_reset_token: passwordResetToken
    };

    return this.http.post(requestPath, body, httpOptions).pipe(
      timeout(this.configService.API_TIMEOUT),
      map(_ => {
        return Result.success(true);
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );
  }

  public get isSignedIn(): boolean {

    const idmValue = this.tokenService.validate(CACHE_IDM_TOKEN);

    const authValue = this.tokenService.validate(CACHE_AUTH_TOKEN);

    return idmValue && authValue;
  }

  public signOut() {
    this.cacheService.clear([CACHE_SUPPORT_ADDED]);
    this.idleTimeoutService.stopTimer();
    this.onSignedInStatusChanged.next(false);
    localStorage.removeItem('simswop');
    localStorage.removeItem(CACHE_GENERATE_VOUCHER);
    localStorage.removeItem(CACHE_SUPPORT_SALE_UPGRADE_ADDED);
    
    this.store.dispatch([
      new ClearServicePolicies(),
      new ResetUserBillingAccountStatus(), 
      new ResetOrders(), 
      new ResetCart(), 
      new ClearTokens(),
      new CoverageCheckedOnRegistration(false),
      new ClearState(),   
      new ClearServicesState(),
      new UIActions.SwitchUIMode({mode: "consumer"}),
      new ClearProfile(),
      new ClearBillingState(),
      new ClearCoverageState()
    ]);
    this.billingStore.clearAllValues();
  }
}
