import { UserAddress } from '@services/coverageDetail';
import * as CoverageActions from '../actions/coverage.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

export interface CoverageStateModel {
    address: UserAddress;
  }

  const defaults = () => {
    return {
      address: {
        streetNumber: '',
        streetName: '',
        city: '',
        suburb: '',
        postalCode: '',
        latitude: null,
        longitude: null
      }
    };
  }
  
  @State<CoverageStateModel>({
    name: 'coverage',
    defaults: defaults()
  })
  
  @Injectable({ providedIn: 'root' })
  export class CoverageState {
    @Selector()
    static getUserAddress(state: CoverageStateModel) {
      return state.address;
    }
  
    @Action(CoverageActions.SetUserAddress)
    setUserAddress(ctx: StateContext<CoverageStateModel>, action: CoverageActions.SetUserAddress) {
      ctx.patchState({
          address: action.address,
      })
    }

    @Action(CoverageActions.ClearCoverageState)
    clearCoverageState(ctx: StateContext<CoverageStateModel>) {
      ctx.setState(
        defaults()
      )
    }
  }
