import {Injectable} from '@angular/core';
import {LogisticStatus} from '@models/logisticDetail';
import {IOrderDetail} from '@models/orderDetail';
import {Result} from '@models/result';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {OrderService} from '@services/order.service';
import {tap} from 'rxjs/operators';
import {
  GetAllOrders,
  GetOneOrder,
  HasRainOneOrder,
  ResetOrders,
  SetCurrentOrder,
  SetLoadingStatus,
  SetOrders
} from '../actions/order.actions';
import {ProductState} from './product.state';
import {concat} from 'lodash';
import {RainOneProduct} from 'src/app/core/interfaces/rain-one-products.interface';
import {ServicesState} from './services.state';
import {RAINONE_101_5G_SIM, RAINONE_5G_SIM} from '@models/constants';
import {UIState} from 'src/app/shared/store/state/ui.state';
import {AuthState} from 'src/app/core/store/state/auth.state';
import {EMPTY} from 'rxjs';

const activeOrderStatus = [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export interface OrderStateModel {
  hasRainOneOrder: boolean;
  allOrders: IOrderDetail[];
  currentOrder: IOrderDetail;
  isLoading: boolean;
  migrations_tracker: {
    ready: boolean;
    five_g: {
      hasActive: boolean;
      count: number;
    };
    four_g: {
      hasActive: boolean;
      count: number;
      uop: {
        hasActive: boolean;
        count: number;
      };
      unlmtd: {
        hasActive: boolean;
        count: number;
      };
    };
  };
}

@State<OrderStateModel>({
  name: 'orders',
  defaults: {
    hasRainOneOrder: false,
    allOrders: [],
    currentOrder: null,
    isLoading: false,
    migrations_tracker: {
      ready: false,
      five_g: {
        hasActive: false,
        count: 0
      },
      four_g: {
        hasActive: false,
        count: 0,
        uop: {
          hasActive: false,
          count: 0
        },
        unlmtd: {
          hasActive: false,
          count: 0
        }
      }
    }
  }
})
@Injectable({ providedIn: 'root' })
export class OrderState {



  @Selector([OrderState])
  static allOrders(state: OrderStateModel) {
    return state.allOrders;
  }

  @Selector([OrderState])
  static isLoading(state: OrderStateModel) {
    return state.isLoading;
  };

  @Selector([OrderState])
  static oderById(state: OrderStateModel) {
    return (orderId: string) => {
      return state.allOrders.find(order => order.id === orderId);
    };
  }
  @Selector([OrderState])
  static currentOrder(state: OrderStateModel) {
    return state.currentOrder;
  }

  @Selector([OrderState])
  static GetallNonCancelledOrders(state: OrderStateModel) {
    return state.allOrders.filter(o => o.status !== 6);
  }

  @Selector([OrderState])
  static HasActiveOrders(state: OrderStateModel) {
    const activeStates = [
      LogisticStatus.PaymentNotCompleted,
      LogisticStatus.OrderProcessed,
      LogisticStatus.QueuedForDelivery,
      LogisticStatus.PartiallyDelivered,
      LogisticStatus.QueuedForCollection,
      LogisticStatus.PartiallyCollected,
      LogisticStatus.PartialRICA
    ]

    return state.allOrders.some(o => activeStates.includes(o.status));
  }

  @Selector([OrderState])
  static GetTrackingData(state: OrderStateModel) {
    return state.migrations_tracker;
  }

  @Selector([OrderState])
  static HasOpenUOPOrder(state: OrderStateModel) {
    return state.migrations_tracker.four_g.uop.hasActive;
  }
  @Selector([OrderState])
  static GetAllSmeOrders(state: OrderStateModel) {
    return state.allOrders.filter(order => order.orderComplete && order.items.length >= 5)
  }

  @Selector([OrderState])
  static HasOpenUnlmtdOrder(state: OrderStateModel) {
    return state.migrations_tracker.four_g.unlmtd.hasActive;
  }

  @Selector([OrderState])
  static allPostPaidProductIdsSet(state: OrderStateModel) {
    const allProductIds = state.allOrders
      .filter(order => order.status !== LogisticStatus.OrderCancelled)
      .map(order => order.items)
      .reduce((prev, curr) => {
        return curr.concat(prev);
      }, [])
      .map(item => item.productId);
    return [...allProductIds];
  }

  @Selector([OrderState])
  static ExistingRainOneOrder(state: OrderStateModel) {
    return state.hasRainOneOrder;
  }

  @Selector([OrderState, ProductState.GetRainOneL1, ProductState.GetRainOneL1PP])
  static hasRainOneOrderV2(state: OrderStateModel, productL1: RainOneProduct, productL1PP: RainOneProduct) {
    // const allNonCancelledOrders = state?.allOrders?.filter(o => o.status !== 6) ?? [];
    const allNonCancelledOrders = state?.allOrders?.filter(o => activeOrderStatus.includes(o.status)) ?? [];
    const rainOneProductIds = [...RAINONE_101_5G_SIM, ...RAINONE_5G_SIM];
    // const rainOneProductIds = concat(productL1?.items ?? [], productL1PP?.items ?? []).map(items => items.id);
    
    const orderProductIds = allNonCancelledOrders
      .map(order => {
        if (order?.status !== LogisticStatus.Delivered && order.status !== LogisticStatus.Collected)
          return order?.items?.map(item => item?.productId);
      })
      .reduce((accum, current) => accum?.concat(current), []);

    let hasRainOne = false;
      
    if (orderProductIds && orderProductIds.length > 0) {
      orderProductIds.forEach(OrderProductId => {
        rainOneProductIds.forEach(rainOneProductId => {
          if (OrderProductId === rainOneProductId) {
            hasRainOne = true;
          }
        });
      });
    }

    return hasRainOne;
  }

  constructor(private orderService: OrderService, private store: Store) {}

  @Action(GetAllOrders)
  getAllOrders(ctx: StateContext<OrderStateModel>, action: GetAllOrders) {
        
    let uiMode = this.store.selectSnapshot(UIState.GetUIMode);
    let smeToken = this.store.selectSnapshot(AuthState.getSmeToken);
    if (uiMode === 'sme' && smeToken) {
      return this.orderService.getAllSmeOrders(true).pipe(
        tap((orders: Result<IOrderDetail[]>) => {
          if (orders && orders.value) {
            ctx.dispatch(new SetOrders(orders.value));
          }
        })
      );
    } 
    return this.orderService.getAll(true).pipe(
      tap((orders: Result<IOrderDetail[]>) => {
       
        if (orders && orders.value) {
          ctx.dispatch(new SetOrders(orders.value));
        } else {
          return EMPTY;
        }
      })
    );

   
  }

  @Action(SetLoadingStatus)
  SetLoadingStatus(ctx: StateContext<OrderStateModel>, action: SetLoadingStatus) {
      ctx.patchState({
        isLoading: action.payload
      });
  }

  @Action(SetOrders)
  SetOrders(ctx: StateContext<OrderStateModel>, action: SetOrders) {
    const orders = action.payload;
    const uopIDS = this.store.selectSnapshot(ProductState.GetRainOneUOPIDs);
    const unlmtdIDS = this.store.selectSnapshot(ProductState.GetRainOneUnlimtedIDs);
    
    if (orders && orders) {
      let uopOrders = [];
      let unlmtdOrders = [];
      let fiveGOrders = [];

      orders.forEach(o =>
        o.items.forEach(ol => {
          const product = this.store.selectSnapshot(ProductState.GetProductById(ol.productId));
          if (activeOrderStatus.includes(o.status)) {
            if(product?.category === '5G' && !fiveGOrders.includes(o)) {
              return fiveGOrders.push(o)
            }
            if (unlmtdIDS.includes(ol.productId)) {
              if (!unlmtdOrders.includes(o)) return unlmtdOrders.push(o);
            }
            if (uopIDS.includes(ol.productId)) {
              if (!uopOrders.includes(o)) return uopOrders.push(o);
            }
          }
        })
      );

      const has4GUOPRainOne = this.store.selectSnapshot(ServicesState.getAllServices).filter(svc => svc?.productName?.toLowerCase().includes('rainone 4g unlimited off-peak'))
  
      const has4GRainOne = this.store.selectSnapshot(ServicesState.getAllServices).filter(svc => svc?.productName?.toLowerCase().includes('rainone 4g unlimited') && !svc?.productName?.toLowerCase().includes('off-peak'));

      ctx.patchState({
        allOrders: orders,
        isLoading: false,
        migrations_tracker: {
          ready: true,
          four_g: {
            hasActive: Boolean(uopOrders.length > 0 || unlmtdOrders.length > 0),
            count: uopOrders.length + unlmtdOrders.length,
            uop: {
              hasActive: Boolean(uopOrders.length > 0 || has4GUOPRainOne.length > 0),
              count: this.maxCount(uopOrders.length, has4GUOPRainOne.length)
            },
            unlmtd: {
              hasActive: Boolean(unlmtdOrders.length > 0 || has4GRainOne.length > 0),
              count: this.maxCount(unlmtdOrders.length, has4GRainOne.length)
            }
          },
          five_g: {
            count: fiveGOrders.length,
            hasActive: Boolean(fiveGOrders.length > 0)
          }
        }
      });
    }
  }

  @Action(GetOneOrder)
  getOneOrder(state: StateContext<OrderStateModel>, action: GetOneOrder) {
    const orders = state.getState().allOrders;

    state.patchState({
      currentOrder: orders.find(order => order.id === action.orderId)
    });
  }
  @Action(SetCurrentOrder)
  SetCurrentOrder(state: StateContext<OrderStateModel>, action: SetCurrentOrder) {
    state.patchState({
      currentOrder: action.order
    });
  }

  @Action(ResetOrders)
  resetOrders(state: StateContext<OrderStateModel>, action: ResetOrders) {
    state.patchState({
      currentOrder: null,
      allOrders: [],
      hasRainOneOrder: false,
      migrations_tracker: {
        ready: false,
        five_g: {
          hasActive: false,
          count: 0
        },
        four_g: {
          hasActive: false,
          count: 0,
          uop: {
            hasActive: false,
            count: 0
          },
          unlmtd: {
            hasActive: false,
            count: 0
          }
        }
      }
    });
  }

  @Action(HasRainOneOrder)
  hasRainOneOrder(state: StateContext<OrderStateModel>, action: HasRainOneOrder) {
    const allNonCancelledOrders = state.getState().allOrders?.filter(o => o.status !== 6);
    const rainOneProductIds = concat(
      this.store.selectSnapshot(ProductState.GetRainOneL1)?.items,
      this.store.selectSnapshot(ProductState.GetRainOneL1PP)?.items
    ).map(items => items.id);

    if (allNonCancelledOrders && allNonCancelledOrders.length > 0) {
      const orderProductIds = allNonCancelledOrders
        ?.map(order => {
          if (order && order?.status !== LogisticStatus.Delivered && order.status !== LogisticStatus.Collected)
            return order?.items?.map(item => item?.productId);
        })
        ?.reduce((accum, current) => accum?.concat(current));

      if (orderProductIds && orderProductIds.length > 0) {
        orderProductIds.forEach(OrderProductId => {
          rainOneProductIds.forEach(rainOneProductId => {
            if (OrderProductId === rainOneProductId)
              return state.patchState({
                hasRainOneOrder: true
              });
          });
        });
      }
    }
  }

  private maxCount(numOrders, numActiveSvc) {
    if (numOrders === numActiveSvc) return numOrders;
    if(numOrders > numActiveSvc) return numOrders;
    if(numOrders < numActiveSvc) return numActiveSvc;
  }
}
